<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-breadcrumb>
          <b-breadcrumb-item active>Perfil</b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3">
        <b-card-group deck>
          <b-card :title="usuario.nombre" :img-src="$Base_url + 'archivos/imagenes/' + usuario.imagen" img-alt="Image" img-top>
            <b-card-text>
              <div class="table-responsive table01">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Usuario</td>
                      <td>{{usuario.usuario}}</td>
                    </tr>
                    <tr>
                      <td>Correo</td>
                      <td>{{usuario.correo}}</td>
                    </tr>
                    <tr>
                      <td>Tipo</td>
                      <td>{{usuario.tipo}}</td>
                    </tr>
                    <tr>
                      <td>Estado</td>
                      <td v-if="usuario.estado == 0">Deshabilitado</td>
                      <td v-if="usuario.estado == 1">Activo</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-text>
          </b-card>        
        </b-card-group>
      </b-col>
      <b-col lg="9">
        <Widget title="" customHeader>
          <input type="file" @change="onfileselecter">
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'perfil',
  components: { Widget },
  data() {
    return {
      seletedfile: null,
      usuario : []     
    }
  },
  methods:{
    onfileselecter(event){
      this.seletedfile = event.target.files[0];
    },
    async Usuario(){
      try {
        let token = await localStorage.getItem("user_token");
        let id_usuario = await localStorage.getItem("user_id");
        let result = await this.$JQuery.post(this.$Base_url + `Perfil/Usuario?token=${token}`, {'id_usuario': id_usuario } );
        this.usuario = JSON.parse(result)
      }catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos!!'
        })
      }
    },
  },
  mounted () {
    this.Usuario();
  },
}
</script>

<style src="./Perfil.scss" lang="scss" scoped />
