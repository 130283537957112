<template src="./Template.vue"></template>

<script>
import Push from 'push.js';
const opciones = {
  icon: '/48.png',
  timeout: 4000,
  onClick: function () {
      this.close();
  }
};

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  components: { Loading },
  data () {
    return {
      buscar : '',
      limite : 30,
      item:[],
      Load_usuario  : false,
      NUM_RESULTS   : 15, 
      pag           : 1,
      button        : false,
      modal_usuario : false,
      Input_usuario : {
                      id       : 0 ,
                      nombre   : '' ,
                      usuario  : '' ,
                      password : '' ,
                      correo   : '' ,
                      estado   : 1 ,
                      tipo     : '' ,
                      token    : '',
                      tokenAdmin : ''
      }

    }
  },
  methods: {
    async Usuarios(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Configuracion/Usuarios?token=${token}`, this.datos );
        this.item = JSON.parse(result)
      }catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos!!'
        })
      }
    },
    VaciarModalUsuario(){
      this.Input_usuario.id       = '0'
      this.Input_usuario.nombre   = ''
      this.Input_usuario.password = ''
      this.Input_usuario.usuario  = ''
      this.Input_usuario.correo   = ''
      this.Input_usuario.estado   = 1
      this.Input_usuario.tipo     = 'Usuario'
      this.Input_usuario.tokenAdmin = localStorage.getItem("user_token");
    },
    LlenarUsuario(usuario){
      this.Input_usuario.id       = usuario.id
      this.Input_usuario.nombre   = usuario.nombre
      this.Input_usuario.password = ''
      this.Input_usuario.usuario  = usuario.usuario
      this.Input_usuario.correo   = usuario.correo
      this.Input_usuario.estado   = usuario.estado
      this.Input_usuario.tipo     = usuario.tipo
      this.Input_usuario.tokenAdmin = localStorage.getItem("user_token");
    },
    async Crear_Editar_usuario(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Configuracion/GE_usuario?token=${token}`, this.Input_usuario );
        if( result == 0){
          this.modal_usuario = false
          this.$Toast.fire({
            type: 'success',
            title: 'Usuario Creado'
          })
        }else if(result == 1){
          this.modal_usuario = false
          this.$Toast.fire({
            type: 'warning',
            title: 'Usuario Editado'
          })
        }else{
          this.$Toast.fire({
          type: 'error',
          title: result
        })
        } 
        this.Usuarios()        
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Error en usuario!!'
        })
      }
    },
    notificacion(){
      Push.create("Hello world!", opciones);
    },
    async actualizar_pagina(){
      let valor = await this.$Notific.fire({
                                title: 'Notificación',
                                text : 'Se mandara una notificación a todos los usuarios.',
                                confirmButtonText: 'Si, Notificar.',
                                cancelButtonText: 'No, cancelar!!',
                              }).then((result) => {
                                if (result.value) {
                                  return true
                                }
                              })  
      if(valor){
        this.$socket.emit('Actualizar_Pagina',{ 'pagina' : true });
      }
    }
  },
  mounted () {
    this.Usuarios();
  },
}
</script>

<style src="./Usuarios.scss" lang="scss" scoped />