<template>
  <b-navbar class="header d-print-none">
    <b-nav>
      <b-nav-item >
        <a style="color:#FFFFFF;" class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
          <i class='la la-bars la-lg' />
        </a>
        <!-- <b-tooltip target="barsTooltip" placement="bottom">
          Encender / apagar <br> la barra lateral           
        </b-tooltip> -->
        <a style="color:#FFFFFF" class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
          <span class="rounded rounded-lg bg-gray text-white d-md-none">
            <i class="la la-bars la-lg" />
          </span>
          <i class="la la-bars la-lg d-sm-down-none ml-4" />
        </a>
      </b-nav-item>
    </b-nav>
    <a  class="navbarBrand d-md-none">
      <i class="fa fa-circle" style="color:#495057;"/>
      &nbsp;
      <i style="color:#FFFFFF;font-size: 15px;">Macoda</i>
      &nbsp;
      <i class="fa fa-circle mr-n-sm" style="color:#495057;"/>
    </a>
    <b-nav class="ml-auto">
      <b-nav-item-dropdown class="d-sm-down-none mr-2" right>
        <template slot="button-content">
          <span class="avatar thumb-sm float-left mr-2">
            <img class="rounded-circle" :src="$Base_url + 'archivos/imagenes/' + imagen_user" alt="..." />
          </span>
          <span class="small" style="color:#FFFFFF">{{nombre_perfil}}</span>
        </template>
        <b-dropdown-item @click="Perfil"><i class="la la-user" /> Perfil</b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="Facturas">
          Factura Pendiente &nbsp;&nbsp;<b-badge variant="danger" pill class="animated bounceIn" v-text="factura_pendientes"></b-badge>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item-button @click="logout">
          <i class="la la-sign-out" /> Salir
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
      <li class="nav-item fs-lg d-md-none">
        <a target="_self" class="nav-link">
          <a href="#"  @click="logout">
            
            <span class="rounded rounded-lg bg-gray text-white">
              <i class="la la-sign-out"></i>
            </span>
          </a>
        </a>
      </li>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Headed',
  computed: {
    ...mapState('layout', {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic,
    }),
  },
   data() {
    return {
      nombre_perfil      : '',
      imagen_user        : '',
      factura_pendientes : 0,
    }
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar', 'switchSidebar', 'changeSidebarActive']),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    logout() {
      // localStorage.clear();
      sessionStorage.clear();
      window.localStorage.removeItem('Logiado');
      window.localStorage.removeItem('user_id');
      window.localStorage.removeItem('user_nombre');
      window.localStorage.removeItem('user_usuario');
      window.localStorage.removeItem('user_token');
      window.localStorage.removeItem('sidebarStatic', this.sidebarStatic);
      this.$router.push('/login');
    },
    Perfil() {
      this.$router.push('/app/perfil');
    },
    Facturas() {
      this.$router.push('/app/facturas/tab02');
    },
    async Facturas_Pendientes(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Otro/FacturasPendientes?token=${token}`);
        this.factura_pendientes = result;
        this.$Toast.fire({
          type: 'error',
          title: 'Tiene ( ' + result + ' ) factura pendiente!!!'
        })
      } catch (error) {
        //error
      }
    }
  },
  mounted: function () {
    this.$nextTick(async function () {
      this.Facturas_Pendientes();
      this.nombre_perfil = await localStorage.getItem("user_nombre");
      this.imagen_user = await localStorage.getItem("user_imagen");
    })
  },
  created() {
    
  },
};
</script>

<style src="./Header.scss" lang="scss" scoped />
