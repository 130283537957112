<template src="./template.vue"></template>

<script>
import Widget from "@/components/Widget/Widget";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment-timezone";
moment.tz.setDefault("America/Santiago");

export default {
  name: "EditTrabajo",
  components: { Widget, Loading },
  data() {
    return {
      id_EditTrabajo: this.$route.params.id,
      Load_trabajo: false,
      Load_abono: false,
      Load_detalle: false,
      form_abono: true,
      form_trabajo: true,
      array_clientes: [],
      array_vehiculos: [],
      array_abono: [],
      array_detalle: [],
      array_productos: [],
      input_subtotal: "$ 0",
      input_abono: "$ 0",
      input_total: "$ 0",
      Buscar_producto: "",
      modal_Productos: false,
      modal_Cliente: false,
      modal_Vehiculo: false,
      detalle_cliente: {
        id_cliente: "",
        telefono_fijo: "",
        telefono_celu: "",
        id_vehiculo: "",
        marca: "",
        modelo: "",
        fecha_inicio: moment().format("YYYY-MM-DD"),
        fecha_fin: moment().format("YYYY-MM-DD"),
        estado: "",
        observacion: "",
        tipo_venta: "",
        eliminado: 0,
        id_trabajo: 0,
      },
      detalle_abono: {
        id: 0,
        abono: "ABONO",
        total: "",
        fecha: moment().format("YYYY-MM-DD"),
        id_trabajo: "",
        editar: false,
      },
      Detalle_trabajo: {
        id: 0,
        detalle: "",
        total: 0,
        id_trabajo: "",
        id_producto: "",
        codigo: 0,
        codigo_barra: "",
        cantidad: 0,
        sub_total: 0,
        editar: false,
      },
      form_cliente: {
        id: 0,
        nombre: "",
        telefono_fijo: "",
        telefono_celu: "",
        editar: false,
      },
      form_vehiculo: {
        id: 0,
        id_cliente: "",
        patente: "",
        marca: "",
        modelo: "",
        editar: false,
      },
      NUM_RESULTS1: 20,
      pag1: 1,
    };
  },
  methods: {
    async Llenar_trabajo() {
      try {
        let token = await localStorage.getItem("user_token");
        this.Load_trabajo = true;
        this.Load_abono = true;
        this.Load_detalle = true;

        await this.Listar_clientes();
        let idtrabajo = await this.$route.params.id;
        let resTrabajo = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/listartrabajo?token=${token}`,
          { id: idtrabajo }
        );
        let datos = await JSON.parse(resTrabajo);
        await this.Listar_vehiculos(datos.DetalleTrabajo.guia_id_dueno);

        this.detalle_cliente.fecha_inicio = datos.DetalleTrabajo.fecha_inicio;
        this.detalle_cliente.fecha_fin    = datos.DetalleTrabajo.fecha_fin;
        this.detalle_cliente.estado       = datos.DetalleTrabajo.estado;
        this.detalle_cliente.observacion  = datos.DetalleTrabajo.observacion;
        this.detalle_cliente.tipo_venta   = datos.DetalleTrabajo.tipo_venta;
        this.detalle_cliente.eliminado    = datos.DetalleTrabajo.eliminado;
        this.detalle_cliente.id_trabajo   = datos.DetalleTrabajo.id;
        this.select_cliente(datos.DetalleTrabajo.guia_id_dueno);
        this.select_vehiculo(datos.DetalleTrabajo.guia_id_vehiculo);
        this.detalle_abono.id_trabajo     = datos.DetalleTrabajo.id;
        this.Detalle_trabajo.id_trabajo   = datos.DetalleTrabajo.id;

        this.array_abono = await datos.abono
        this.array_detalle = await datos.Detalle
        // await this.listar_abono();
        // await this.listar_detalle();
        await this.listar_totales();

        this.form_abono = false;
        this.form_trabajo = false;
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Problemas en cargar trabajo!!",
        });
      } finally {
        this.Load_trabajo = false;
        this.Load_abono = false;
        this.Load_detalle = false;
      }
    },
    async Listar_clientes() {
      try {
        // this.array_clientes = await [];
        let token = await localStorage.getItem("user_token");
        let respuesta = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/listarclientes?token=${token}`
        );
        this.array_clientes = JSON.parse(respuesta);
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Problemas en cargar clientes!!",
        });
      }
    },
    select_cliente(valor, vehiculo = true) {
      for (let cliente of this.array_clientes) {
        if (valor == cliente.id) {
          this.detalle_cliente.id_cliente = cliente.id;
          this.detalle_cliente.telefono_fijo = cliente.telefono_fijo;
          this.detalle_cliente.telefono_celu = cliente.telefono_celular;
          if (vehiculo) {
            this.detalle_cliente.id_vehiculo = "";
            this.detalle_cliente.marca = "";
            this.detalle_cliente.modelo = "";
            this.form_vehiculo.id_cliente = cliente.id;
          }
          this.Listar_vehiculos(cliente.id);
        }
      }
    },
    async Listar_vehiculos(valor) {
      try {
        this.array_vehiculos = await [];
        let token = await localStorage.getItem("user_token");
        let resVehiculo = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/listarvehiculos?token=${token}`,
          { id_cliente: valor }
        );
        this.array_vehiculos = await JSON.parse(resVehiculo);
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Problemas en cargar vehiculos!!",
        });
      }
    },
    select_vehiculo(valor) {
      for (let vehiculo of this.array_vehiculos) {
        if (valor == vehiculo.id) {
          this.detalle_cliente.id_vehiculo = vehiculo.id;
          this.detalle_cliente.marca = vehiculo.marca;
          this.detalle_cliente.modelo = vehiculo.modelo;
        }
      }
    },
    async listar_abono() {
      try {
        // this.array_abono = await [];
        const id = this.detalle_cliente.id_trabajo;
        let token = await localStorage.getItem("user_token");
        let resAbono = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/listarabono?token=${token}`,
          { id_trabajo: id }
        );
        this.array_abono = await JSON.parse(resAbono);
      } catch (error) {
        //error
      }
    },
    async listar_totales() {
      try {
        let token = await localStorage.getItem("user_token");
        const id = this.detalle_cliente.id_trabajo;
        let restotales = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/listartotales?token=${token}`,
          { id_trabajo: id }
        );
        let totales = JSON.parse(restotales);
        this.input_subtotal = "$ " + totales.subtotal;
        this.input_abono = "$ " + totales.abono;
        this.input_total = "$ " + totales.total;
      } catch (error) {
        //error
      }
    },
    async GE_detalleTrabajo() {
      try {
        let token = await localStorage.getItem("user_token");
        let restrabajo = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/GEtrabajo?token=${token}`,
          this.detalle_cliente
        );
        if (restrabajo >= 1) {
          this.detalle_cliente.id_trabajo = restrabajo;
          this.detalle_abono.id_trabajo = restrabajo;
          this.Detalle_trabajo.id_trabajo = restrabajo;
          this.form_abono = false;
          this.form_trabajo = false;
        } else if (restrabajo == 0) {
          this.$Toast.fire({
            type: "info",
            title: "Trabajo editado!!",
          });
        }
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en trabajo!!",
        });
        console.log(error)
      }
    },
    async GE_cliente() {
      try {
        let token = await localStorage.getItem("user_token");
        let rescliente = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/GEcliente?token=${token}`,
          this.form_cliente
        );
        await this.Listar_clientes();
        if (rescliente >= 1) {
          await this.select_cliente(rescliente);
          this.$Toast.fire({
            type: "success",
            title: "Cliente creado",
          });
        } else if (rescliente == 0) {
          await this.select_cliente(this.detalle_cliente.id_cliente, false);
          this.$Toast.fire({
            type: "info",
            title: "Cliente editado",
          });
        }
        this.modal_Cliente = false;
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en cliente!!",
        });
      }
    },
    async GE_vehiculo() {
      try {
        let token = await localStorage.getItem("user_token");
        let resvehiculo = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/GEvehiculo?token=${token}`,
          this.form_vehiculo
        );
        await this.Listar_vehiculos(this.detalle_cliente.id_cliente);
        if (resvehiculo >= 1) {
          await this.select_vehiculo(resvehiculo);
          this.$Toast.fire({
            type: "success",
            title: "Vehiculo creado",
          });
        } else if (resvehiculo == 0) {
          await this.select_vehiculo(this.detalle_cliente.id_vehiculo);
          this.$Toast.fire({
            type: "info",
            title: "Vehiculo editado",
          });
        }
        this.modal_Vehiculo = false;
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en vehiculo!!",
        });
      }
    },
    async Eliminar_vehiculo(id) {
      this.$Notific
        .fire({
          title: "Quisiera eliminar este vehiculo!!!",
        })
        .then((result) => {
          if (result.value) {
            this.elimimar("EliminarVehiculo", "vehiculo", id);
          }
        });
    },
    async GE_abono() {
      try {
        let token = await localStorage.getItem("user_token");
        let resabono = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/GEabono?token=${token}`,
          this.detalle_abono
        );
        if (resabono == 0) {
          this.$Toast.fire({
            type: "success",
            title: "Se creo nuevo abono",
          });
        } else if (resabono == 1) {
          this.$Toast.fire({
            type: "info",
            title: "Abono actualizado",
          });
        }
        this.detalle_abono.id = 0;
        this.detalle_abono.abono = "ABANO";
        this.detalle_abono.total = "";
        this.detalle_abono.fecha = moment().format("YYYY-MM-DD");
        this.detalle_abono.editar = false;
        await this.listar_abono();
        await this.listar_totales();
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en abono!!",
        });
      }
    },
    async Eliminar_abono(id) {
      try {
        let valor = await false;
        valor = await this.$Notific
          .fire({
            title: "Quisiera eliminar este abono!!!",
          })
          .then((result) => {
            if (result.value) {
              return true;
            }
          });
        if (valor) {
          await this.elimimar("EliminarAbono", "abono", id);
          await this.listar_abono();
          await this.listar_totales();
        }
      } catch (error) {
        //error
      }
    },
    CEabono() {
      this.detalle_abono.id = 0;
      this.detalle_abono.abono = "ABONO";
      this.detalle_abono.total = "";
      this.detalle_abono.fecha = moment().format("YYYY-MM-DD");
      this.detalle_abono.editar = false;
    },
    ModalCliente(valor) {
      this.form_cliente.editar = valor;
      this.modal_Cliente = true;
      if (valor) {
        for (let cliente of this.array_clientes) {
          if (this.detalle_cliente.id_cliente == cliente.id) {
            this.form_cliente.id = cliente.id;
            this.form_cliente.nombre = cliente.nombre;
            this.form_cliente.telefono_fijo = cliente.telefono_fijo;
            this.form_cliente.telefono_celu = cliente.telefono_celular;
          }
        }
      } else {
        this.form_cliente.id = 0;
        this.form_cliente.nombre = "";
        this.form_cliente.telefono_fijo = "";
        this.form_cliente.telefono_celu = "";
      }
    },
    ModalVehiculo(valor) {
      this.form_vehiculo.editar = valor;
      this.modal_Vehiculo = true;
      if (valor) {
        for (let vehiculo of this.array_vehiculos) {
          if (this.detalle_cliente.id_vehiculo == vehiculo.id) {
            this.form_vehiculo.id = vehiculo.id;
            this.form_vehiculo.patente = vehiculo.patente;
            this.form_vehiculo.marca = vehiculo.marca;
            this.form_vehiculo.modelo = vehiculo.modelo;
          }
        }
      } else {
        this.form_vehiculo.id = 0;
        this.form_vehiculo.patente = "";
        this.form_vehiculo.marca = "";
        this.form_vehiculo.modelo = "";
      }
    },
    // ---------   detalle de trabajo  -----------------------
    async listar_detalle() {
      try {
        // this.array_detalle = [];
        const id = this.detalle_cliente.id_trabajo;
        let token = await localStorage.getItem("user_token");
        let resDetalle = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/listardetalle?token=${token}`,
          { id_trabajo: id }
        );
        this.array_detalle = JSON.parse(resDetalle);
      } catch (error) {
        //error
      }
    },
    async ListarProductos() {
      try {
        this.array_productos = await [];
        let token = await localStorage.getItem("user_token");
        let resProductos = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/ListarProductos?token=${token}`
        );
        this.array_productos = await JSON.parse(resProductos);
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en traer productos!!!",
        });
      }
    },

    async GE_detalle() {
      try {
        this.Load_detalle = true;
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post( this.$Base_url + `EditMecanica/GEdetalle?token=${token}`, this.Detalle_trabajo );
        if (result == "0") {
          this.$Toast.fire({
            type: "success",
            title: "Producto Agregado",
          });
        } else if (result == "1") {
          this.$Toast.fire({
            type: "info",
            title: "Producto Actualizado",
          });
        }
        await this.listar_detalle();
        this.Limpiar();
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en Producto!!",
        });
      } finally {
        this.Load_detalle = false;
        this.$socket.emit('Update_table_producto');
        await this.listar_totales();
      }
    },
    async Agregar_producto(producto) {
      var productos = {
        id_producto: producto.id,
        codigo: producto.codigo,
        codigo_barra: producto.codigo_de_barra,
        detalle: producto.nombre,
        total: producto.precio,
        id_trabajo: this.detalle_cliente.id_trabajo,
      };
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post( this.$Base_url + `EditMecanica/AgregarProducto?token=${token}`, productos );
        if (result == "0") {
          this.$socket.emit('Update_table_producto');
          await this.listar_detalle();
          await this.listar_totales();
          this.$Toast.fire({
            type: "success",
            title: "Producto Agregado",
          });
        }
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en Producto!!",
        });
      }
    },
    Agregar_producto2(producto) {
      this.Detalle_trabajo.id_producto = producto.id;
      this.Detalle_trabajo.codigo = producto.codigo;
      this.Detalle_trabajo.codigo_barra = producto.codigo_de_barra;
      this.Detalle_trabajo.cantidad = 1;
      this.Detalle_trabajo.detalle = producto.nombre;
      this.Detalle_trabajo.total = producto.precio;
      this.Detalle_trabajo.sub_total = producto.precio;
      this.modal_Productos = false;
    },
    async Eliminar_detalle(id) {
      try {
        let valor = await this.$Notific
          .fire({
            title: "Quisiera eliminar este producto!!!",
          })
          .then((result) => {
            if (result.value) {
              return true;
            }
          });
        if (valor) {
          await this.elimimar("EliminarDetalle", "producto", id);
          await this.listar_detalle();
          await this.listar_totales();
          this.$socket.emit('Update_table_producto');
          this.Limpiar();
        }
      } catch (error) {
        //error
      }
    },
    async vender_detalle(id) {
      try {
        if(this.detalle_cliente.tipo_venta){
          let token = await localStorage.getItem("user_token");
          let usuario = await localStorage.getItem("user_id")
          let result = await this.$JQuery.post(
              this.$Base_url + `EditMecanica/VenderDetalle?token=${token}`,{ id: id, usuario: usuario , tipo_venta: this.detalle_cliente.tipo_venta, });
          if (result == "0") {
            await this.listar_detalle();
            this.$socket.emit('Update_table_venta');
            this.$Toast.fire({
              type: "success",
              title: "Vnedido",
            });
          }
        }else{
          this.$Toast.fire({
            type: "error",
            title: "Seleccione un Tipo de Venta!",
          });
        }
      } catch (error) {
        console.log(error)
      }
    },
    async devolver_detalle(id) {
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(
          this.$Base_url + `EditMecanica/VenderDevolver?token=${token}`, { id: id });
        if (result == "0") {
          await this.listar_detalle();
          this.$socket.emit('Update_table_venta');
        }
      } catch (error) {
        console.log(error)
      }
    },
    Limpiar() {
      this.Detalle_trabajo.id = 0;
      this.Detalle_trabajo.id_producto = "";
      this.Detalle_trabajo.codigo = 0;
      this.Detalle_trabajo.codigo_barra = "";
      this.Detalle_trabajo.cantidad = 0;
      this.Detalle_trabajo.detalle = "";
      this.Detalle_trabajo.total = 0;
      this.Detalle_trabajo.sub_total = 0;
      this.Detalle_trabajo.editar = false;
    },
    Change_cantidad() {
      var cantidad = this.Detalle_trabajo.cantidad;
      var subtotal = this.Detalle_trabajo.sub_total;
      this.Detalle_trabajo.total = subtotal * cantidad;
    },
    async elimimar(url, texto, id) {
      try {
        let token = await localStorage.getItem("user_token");
        let resabono = await this.$JQuery.post(this.$Base_url + `EditMecanica/${url}?token=${token}`, { id: id });
        if (resabono == 0) {
          this.$Toast.fire({
            type: "error",
            title: texto + " eliminado!!!",
          });
        }
      } catch (error) {
        this.$Toast.fire({
          type: "error",
          title: "Falla en eliminar el " + texto + "!!!",
        });
      }
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.Llenar_trabajo();
    } else {
      this.Listar_clientes();
    }
    this.ListarProductos();
  },
  computed: {
    filtrarProductos: function () {
      return this.array_productos.filter((productos) => {
        return (
          productos.codigo
            .toLowerCase()
            .match(this.Buscar_producto.toLowerCase()) ||
          productos.nombre
            .toLowerCase()
            .match(this.Buscar_producto.toLowerCase()) ||
          productos.codigo_de_barra
            .toLowerCase()
            .match(this.Buscar_producto.toLowerCase())
        );
      });
    },
  },
};
</script>

<style src="./EditTrabajo.scss" lang="scss" scoped />
