<template>
  <b-row> 
    <b-container class="">
      <form @submit.prevent="addImagen" enctype="multipart/form-data">
        <input @change="obtenerImangen" type="file">
        <input type="submit" value="enviar">
      </form>
      <ul v-for="(datos, key) in resultado" :key="key" >
        <li>{{ datos.cantidad }} -{{ datos.detalle }} - {{ datos.total }}</li>
      </ul>
      <button @click="boton_push">boton push</button>

    </b-container>
  </b-row>

</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import push from 'push.js'

export default {
  name: 'Pruebas',
  data() {
    return {
      form : { user: 'daniel', pass:'', imagen:'' },
      imagenMinuatura: '',
      palabara:'',
      resultado:[{cantidad:'1',detalle:'total', total:'1000'}]
    };
  },
  methods: {
    boton_push(){
      push.create('Hello World!')
    },
    async encriptar(){
        // try {
        //     // const bcrypt = require('bcryptjs');
        //     // const rondasDeSal = 10;
        //     // const palabraSecretaEncriptada = await bcrypt.hash(this.form.pass , rondasDeSal);
        //     // this.palabara = palabraSecretaEncriptada
        //     // const result = await this.$JQuery.post(this.$Base_url + `Login/Entrar`, this.form )
        //     // console.log(result)
        // } catch (error) {
        //     // console.log(error)
        // }
    },
    obtenerImangen(e){
      let file = e.target.files[0];
      this.form.imagen = file;
      // this.cargarimagen(file);
    },
    cargarimagen(){
      // let reader = new FileReader();
      // reader.onload = (e) => {

      // }
      // reader.readAsDataURL(file)
    },
    async addImagen(){
      try {
        let formData = new FormData();
        formData.append('imagen', this.form.imagen);
        formData.append('user', this.form.user);
        
        this.$JQuery.ajax({
            url: this.$Base_url + `Pruebas/Subir_imagen`,
            type: 'post',
            data: formData,
            contentType: false,
            processData: false,
            success: function(response) {
                this.resultado = JSON.parse(response)
                console.log(this.resultado)
            }
        });
        
      } catch (error) {
        console.log(error)
      }
      
    }
  },
};
</script>