<template src="./Template.vue"></template>

<script>
import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')

export default {
  name: 'FacturaEdit',
  components: { Widget, Loading, },
  data() {
    return {
      Load_facturaEdit  : false,
      Load_abono        : false,
      Load_detalle      : false,
      colum_descuento   : false,
      id_factura        : this.$route.params.id,
      array_proveedores : [],
      array_factura     : [],
      array_detalle     : [],
      array_abono       : [],
      array_productos   : [],
      id_existe         : null,
      bloquear_input    : true,
      json_factura      : { id_factura       : 0,
                            empresa_factura  : '',
                            numero_factura   : '',
                            total_factura    : '',
                            f_inicio_factura : '',
                            f_venci_factura  : '',
                            estado_factura   : 'PENDIENTE' },
      json_abono        : { id_factura         : 0,
                            editar_abono     : false,
                            id               : 0,
                            detalle_abono    : '',
                            total_abono      : '',
                            fecha_abono      : moment().format('YYYY-MM-DD') },
      json_detalle      : { id_factura       : 0,
                            editar_detalle   : false,
                            id               : 0,
                            codigo_detalle   : '',
                            codigo_interno   : '',
                            cantidad_detalle : '',
                            detalle_detalle  : '',
                            unitario_detalle : '',
                            descuento_detalle: '',
                            total_detalle    : '' },
      totales             : { factura        : 0,
                              abono          : 0,
                              subtotal       : 0
                            }
    }
  },
  methods:{
    async Llenar_factura(load) {
      try {
        if(load){ this.Load_facturaEdit = true; }
        let token = await localStorage.getItem("user_token");

        let empresas = await this.$JQuery.post(this.$Base_url + `FacturaEdit/Listar_empresas?token=${token}` );
        this.array_proveedores = JSON.parse(empresas)

        let result = await this.$JQuery.post(this.$Base_url + `FacturaEdit/Llenar_factura?token=${token}`, { 'id': this.id_factura });
        let datos = await JSON.parse(result)
        let informacion = await datos.Informacion
        this.array_abono  = await datos.Abonos
        this.array_detalle = await datos.Detalles

        this.json_factura.id_factura       = informacion.id;
        this.json_factura.empresa_factura  = informacion.id_empresa;
        this.json_factura.numero_factura   = informacion.numero_factura;
        this.json_factura.total_factura    = informacion.total_facturado;
        this.json_factura.f_inicio_factura = informacion.fecha_facturacion;
        this.json_factura.f_venci_factura  = informacion.fecha_vencimiento;
        this.json_factura.estado_factura   = informacion.estado;
        this.json_abono.id_factura         = informacion.id;
        this.json_detalle.id_factura       = informacion.id;
        this.bloquear_input                = false;

        this.totales.factura = informacion.total_facturado
        this.calculo_factura()

			} catch (error) {
          this.$Toast.fire({
            type: 'error',
            title: 'Falla en cargar datos!!'
          })
      } finally {
        if(load){ this.Load_facturaEdit = false; }
      }    
    },
    async Listar_proveedores(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `FacturaEdit/Listar_empresas?token=${token}` );
        this.array_proveedores = JSON.parse(result)
      } catch (error) {
        //error
      }
    },
    async Listar_abono(){
      try {
        this.Load_abono = true;
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `FacturaEdit/Abonos_factura?token=${token}`, { 'id': this.json_abono.id_factura });
        this.array_abono = await JSON.parse(result)
        this.calculo_factura()
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Problemas en cargar abono!!'
        })
      }finally{
        this.Load_abono = false;
      }
    },
    async Listar_detalle(){
       try {
        this.Load_detalle = true;
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `FacturaEdit/Detalles_factura?token=${token}`, { 'id': this.json_detalle.id_factura });
        this.array_detalle = await JSON.parse(result)
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Problemas en cargar detalle!!'
        })
      }finally{
        this.Load_detalle = false;
      }
    },
    async GE_factura(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `FacturaEdit/GE_Factura?token=${token}`, this.json_factura );
        if(result == '0'){
          this.$Toast.fire({
            type: 'info',
            title: 'Factura editada.'
          })
        }else if(result > '0'){
          this.$Toast.fire({
            type: 'success',
            title: 'Factura creada'
          })
          this.json_factura.id_factura  = result;
          this.json_abono.id_factura    = result;
          this.json_detalle.id_factura  = result;
          this.id_factura               = result;      
          this.bloquear_input           = false;
        }
        this.$socket.emit('Update_table_factura');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Problemas en crear factura!!'
        })        
      }
    },
    async GE_abono(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `FacturaEdit/GE_Abono?token=${token}`, this.json_abono );
        if(result == '0'){
          this.$Toast.fire({
            type: 'info',
            title: 'abono editado'
          })
          this.json_abono.editar_abono = false;
          this.json_abono.id           = 0;
        }else if(result == '1'){
          this.$Toast.fire({
            type: 'success',
            title: 'Abono agregado'
          })
        }
        this.json_abono.detalle_abono = '';
        this.json_abono.total_abono = '';
        this.$socket.emit('Update_table_factura');
        this.$socket.emit('Update_table_factura_abono');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Problemas en agregar abono!!'
        })
      }
    },
    async Eliminar_abono(id){
      try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `FacturaEdit/Eliminar_abono?token=${token}`, {'id':id} );
        this.$Toast.fire({
          type: 'error',
          title: 'Abono eliminado'
        })
        this.$socket.emit('Update_table_factura');
        this.$socket.emit('Update_table_factura_abono');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Problemas en eliminar abono!!'
        })
      }
    },
    async GE_detalle(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `FacturaEdit/GE_Detalle?token=${token}`, this.json_detalle );
        if(result == '0'){
          this.$Toast.fire({
            type: 'info',
            title: 'Detalle editado'
          })
          this.json_detalle.editar_detalle    = false;
          this.json_detalle.id  = 0;
        }else if(result == '1'){
          this.$Toast.fire({
            type: 'success',
            title: 'Detalle agregado'
          })
        }
        this.json_detalle.codigo_detalle = '';
        this.json_detalle.codigo_interno = '';
        this.json_detalle.cantidad_detalle = '';
        this.json_detalle.detalle_detalle = '';
        this.json_detalle.unitario_detalle = '';
        this.json_detalle.descuento_detalle = '';
        this.json_detalle.total_detalle = '';
        this.$socket.emit('Update_table_factura_detalle');
        this.$refs.inputDetalleCodigo.focus();
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Problemas en agregar detalle!!'
        })        
      }
    },
    async Eliminar_detalle(id){
      try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `FacturaEdit/Eliminar_detalle?token=${token}`, {'id':id} );
        this.$Toast.fire({
          type: 'error',
          title: 'Detalle eliminado'
        })
        this.$socket.emit('Update_table_factura_detalle');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Problemas en eliminar Detalle!!'
        })
      }
    },
    editar_detalle(detalle){
      this.json_detalle.editar_detalle =true
      this.json_detalle.id = detalle.id
      this.json_detalle.codigo_detalle =detalle.codigo
      this.json_detalle.codigo_interno =detalle.codigo_interno
      this.json_detalle.cantidad_detalle =detalle.cantidad
      this.json_detalle.detalle_detalle =detalle.detalle
      this.json_detalle.unitario_detalle =detalle.precio_uni
      this.json_detalle.descuento_detalle =detalle.descuento
      this.json_detalle.total_detalle =detalle.precio
    },
    click_file(){
      document.getElementById('archivos').click()
    },
    async change_archivo(e){
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append('imagen', file);
      formData.append('id', this.json_factura.id_factura);
      let token = localStorage.getItem("user_token");

      let id_factura
      this.Load_facturaEdit = true
      await this.$JQuery.ajax({
        url: this.$Base_url + `FacturaEdit/Subir_Factura_o_Detalle?token=${token}`,
        type: 'post',
        data: formData,
        contentType: false,
        processData: false,
        success: function(response) {
          console.log(response)
          if(response != '0'){
            id_factura = response
          }
        }
      });
      document.getElementById("archivos").value = "";
      this.id_factura = id_factura
      await this.Llenar_factura(true);
      await this.$socket.emit('Update_table_factura');
    },
    calculo_factura(){
      let totalAbono = 0
      for( let abono of this.array_abono){
        totalAbono = totalAbono + abono.total
        
      }
      this.totales.abono = totalAbono
      this.totales.subtotal = this.totales.factura - this.totales.abono
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      if(this.id_factura == 0){
        //Nueva Factura
        this.Listar_proveedores()
      }else if(this.id_factura > 0){
        //Editar Factura
        this.Llenar_factura(true);
      }
    });
    this.$socket.on('Update_factura_abono', () => {
      this.Listar_abono();
    });
    this.$socket.on('Update_factura_detalle', () => {
      this.Listar_detalle();
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_factura_abono')
    this.$socket.removeListener('Update_factura_detalle')
  },
  //   watch: {
  //   price: function(newValue) {
  //     const result = newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     this.nextTick(() => this.price = result);
  //   }
  // }
}
</script>

<style src="./FacturaEdit.scss" lang="scss" scoped />
