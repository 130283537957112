<template src="./Template.vue"></template>

<script>

import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import { VueContext } from 'vue-context';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState, mapMutations } from "vuex"

export default {
  name: 'Tab02',
  components: { Widget, Loading, VueContext },
  data() {
    return {
      filtro          :{ estado: 'PENDIENTE',
                         eliminado: 0
      },
      filtro2          :{ estado: 'PAGADO',
                         eliminado: 0
      },
      array_facturas  : [],
      array_facturasPagadas : [],
      Load_facturas   : false,
      Load_facturasPagadas   : false
    }
  },
  methods:{
    ...mapMutations( 'module_text' , ['setFacturaBuscar1','setFacturaBuscar2']),
    ...mapMutations( 'module_paginas' , ['setFacturaPag1','setFacturaPag2','sumarFacturaPag1', 'sumarFacturaPag2', 'restarFacturaPag1', 'restarFacturaPag2']),
    Ordenar_factura(estado){
      if(estado !== this.filtro.estado){
        this.filtro.estado = estado;
        this.Listar_facturas(true);
      }
    },
    Ordenar_factura_activo(estado){
      if(estado == this.filtro.estado){
        return 'active'
      }else{
        return ''
      }
    },
    volver(){
      this.$router.go(-1)
    },
    Nueva_factura() {
      this.$router.push('/app/facturas/facturaedit/0');
    },
    Editar_factura(id){
      this.$router.push('/app/facturas/facturaedit/' + id);
    },
    async Listar_facturas(load) {
      try {
        if(load){ this.Load_facturas = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Facturas/Listar_Facturas?token=${token}`, this.filtro );
        this.array_facturas = JSON.parse(result);
			} catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos en factura!!'
        })
      } finally {
        if(load){ this.Load_facturas = false; }
      }    
    },
    async Listar_facturasPagadas(load) {
      try {
        if(load){ this.Load_facturasPagadas = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Facturas/Listar_FacturasPagadas?token=${token}`, this.filtro2 );
        this.array_facturasPagadas = JSON.parse(result);
			} catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos en factura!!'
        })
      } finally {
        if(load){ this.Load_facturasPagadas = false; }
      }    
    },
    async Eliminar_factura(id){
      try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Facturas/Eliminar_factura?token=${token}`, {'id':id} );
        this.$Toast.fire({
          type: 'error',
          title: 'Factura eliminado!!'
        })
        this.$socket.emit('Update_table_factura');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en eliminar Factura!!'
        })
      }
    },
    async Devolver_factura(id){
      try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Facturas/Restaurar_factura?token=${token}`, {'id':id} );
        this.$Toast.fire({
          type: 'error',
          title: 'Factura devuelto'
        })
        this.$socket.emit('Update_table_factura');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en devolver Factura!!'
        })
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.Listar_facturas(true);
      this.Listar_facturasPagadas(true)
    });
    this.$socket.on('Update_factura', () => {
      this.Listar_facturas(false);
      this.Listar_facturasPagadas(false)
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_factura')
  },
  computed:{
    ...mapState( 'module_text' , ['Factura_buscar1','Factura_buscar2']),
    ...mapState( 'module_paginas' , ['Factura_NUM_RESULTS1','Factura_pag1','Factura_NUM_RESULTS2','Factura_pag2']),
    filtrarfactura:function(){
      return this.array_facturas.filter((factura) => {
        return  factura.nombre_proveedor.toLowerCase().match(this.Factura_buscar1.toLowerCase()) ||
                factura.numero_factura.toLowerCase().match(this.Factura_buscar1.toLowerCase())
      });
    },
    filtrarfacturaPagadas:function(){
      return this.array_facturasPagadas.filter((factura) => {
        return  factura.nombre_proveedor.toLowerCase().match(this.Factura_buscar2.toLowerCase()) ||
                factura.numero_factura.toLowerCase().match(this.Factura_buscar2.toLowerCase())
      });
    }
  }
}
</script>

<style src="./Tab02.scss" lang="scss" scoped />
