<template>
  <div>
    <b-row>
      <b-col lg="9">
        <loading :active.sync="Load_estadisticas" 
          :can-cancel="false" 
          :is-full-page="false"
          color="#007196"
          :height="100"
          :width="100"></loading>
        <div class="form-group d-flex flex-wrap">
          <div class="form-list p-2">
            <h6>Seleccione Año</h6>
            <input @change="Venta_Anual(Ano), button = false" class="form-control " style="width: 100px;" v-model="Ano" type="number" min="1990" max="3000">
          </div>
          <div class="form-list p-2">
            <h6>Total</h6>
            <input type="text" class="form-control mt-0 pt-0" :placeholder="total_a | currency" disabled>
          </div>
        </div> 
        
        <Barchar
          :id         = dataset.id
          :tipo       = dataset.tipo
          :encabeszado= dataset.Encabezado
          :label      = dataset.Label
          :datasets   = dataset.data
        />
      </b-col>
      <b-col lg="3">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Mes</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="temp in item" :key="temp.id">
              <td>{{ temp.mes }}</td>
              <td>{{ temp.total | currency }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Total Venta</td>
              <td class="font-weight-bold">{{ total_a | currency }}</td>
            </tr>
            
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Barchar from '@/components/Barchar/Barchar.vue'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')

export default {
  components: { Barchar, Loading },
  data () {
    return {
      ventaAnual:{
        id          : 'barchar',
        tipo        : 'bar',
        Encabezado  : '',
        Label       : ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
        data        : []
      },
      Ano : moment().format('YYYY'),
      item:[],
      total_a : 0,
      button : false,
      Load_estadisticas: false, 
    }
  },
  methods: {
    async Venta_Anual(ano){
      try {
        this.Load_estadisticas = true;
        var meses = this.ventaAnual.Label;
        this.item = [];
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Estadisticas/Estadisticas_year?token=${token}`, { 'fecha': ano } );
        this.ventaAnual.data =  JSON.parse(result);          
        var data = JSON.parse(result);
        this.ventaAnual.Encabezado = this.Ano;
        let total_A = 0
        for (var i = 0; i < data.length; i++) { 
          this.item.push({ 'mes': meses[i], 'total': data[i] },)
          total_A += data[i]
        }
        this.total_a = total_A
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos!!'
        })        
      } finally {
        this.Load_estadisticas = false;
      }
    }
  },
  mounted () {
    this.Venta_Anual(this.Ano);
    this.$socket.on('Update_venta', () => {
      this.Venta_Anual(this.Ano)
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_venta');
  },
  computed:{
    dataset(){
      return this.ventaAnual;
    }
  }
}
</script>

<style src="./Ventas.scss" lang="scss" scoped />