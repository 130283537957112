<template>
  <div>
    <div>
      <b-row>
        <b-col lg="12">
          <b-button @click="downloadWithCSS" class="btn"><i class="fa fa-print"></i> con CSS</b-button>
          <b-button @click="download" class="btn ml-1"><i class="fa fa-share-square-o"></i> sin CSS</b-button>
          <b-button @click="printFacture" class="btn ml-1 print"><i class="fa fa-share-square-o"></i> jspdf html</b-button>
          <b-button @click="print()" class="btn ml-1"><i class="fa fa-share-square-o"></i> printFacture</b-button>
        </b-col>
      </b-row>
    </div>
    <div >
      <b-row id="div1">
        <b-col lg="12">
            <div>
            <table>
                <tr>
                <th>Month</th>
                <th>Savings</th>
                <th>Savings2</th>
                <th>Savings3</th>
                <th>Savings4</th>
                </tr>
                <tr>
                <td>January</td>
                <td>$100</td>
                </tr>
                <tr>
                <td>February</td>
                <td>$80</td>
                </tr>
            </table>
            </div>
            <div>

            </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import jsPDF from "jspdf";
import html2canvas from "html2canvas"

export default {
  name: 'ImprimirMecanica',
  data() {
    return {
    }
  },
  methods: {
    downloadWithCSS() {
      const doc = new jsPDF();
      /** WITH CSS */
      //var canvasElement = document.createElement('canvas');
      html2canvas(this.$refs.div1, { canvas: 1 }).then(function (canvas) {
        const img = canvas.toDataURL("image/png");
        doc.addImage(img,'JPEG',15,15);
        doc.save("sample.pdf");
      });
    },
    download() {
      const doc = new jsPDF();
      /** WITHOUT CSS */
      const contentHtml = this.$refs.div1.innerHTML;
      doc.fromHTML(contentHtml, 15, 15, {
          width: 170
        });
      doc.save("sample.pdf");
    },
    printFacture() {
      var doc = new jsPDF();
      doc.fromHTML($("#div1").html(),15,15,{"width":600});
      doc.save("prueba.pdf");
    },
    print() {
      window.open("https://pruebas.macoda.cl/code/index.php/api/Pdf/DescargarPDF_productos");   
    }
  }
}
</script>

<style src="./Imprimir.scss" lang="scss" scoped />
