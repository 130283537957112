<template>
  <div>
    <Widget title="" customHeader>
      <b-row>
        <b-col lg="12">
          <b-card title="Card Title" no-body>
          <b-card-header header-tag="nav">
            <b-nav card-header tabs>
              <b-nav-item to="/app/facturas/tab01" exact exact-active-class="active">Proveedores</b-nav-item>
              <b-nav-item to="/app/facturas/tab02" exact exact-active-class="active">Facturas</b-nav-item>
            </b-nav>
          </b-card-header>
          <b-card-body>
            <router-view></router-view>
          </b-card-body>
        </b-card>
        </b-col>
      </b-row>
     </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'Facturas',
  components: { Widget },
  data() {
    return {
      
    }
  },
  created(){
    if(this.$route.name == 'Facturas'){
      this.$router.push('/app/facturas/tab01');
    }
  },
}
</script>

<style src="./Facturas.scss" lang="scss" scoped />
