<template src="./Template.vue"></template>

<script>

import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Tab01',
  components: { Widget, Loading },
  data() {
    return {
      array_proveedor : [],
      input_buscar    : '',
      Load_proveedor  : false,
      modal_proveedor : false,
      GE_modal        : { id : 0,
                          empresa_nombre    : '',
                          empresa_pagina    : '',
                          rut               : '',
                          empresa_correo    : '',
                          empresa_telefono  : '',
                          vendedor_nombre   : '',
                          vendedor_telefono : '',
                          vendedor_correo   : '',
                          banco_nombre      : '',
                          banco_tipocuenta  : '',
                          banco_rut         : '',
                          banco_numerocuenta: '',
                          eliminado         : '' ,
                          cbx_tipo          : 0 },
      NUM_RESULTS     : 20,
      pag             : 1,
    }
  },
  methods:{
    async Listar_proveedor(load) {
      try {
        if(load){ this.Load_proveedor = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Empresas/Listar_Empresas?token=${token}`, { 'cbx_tipo':this.GE_modal.cbx_tipo } );
        this.array_proveedor = JSON.parse(result)
			} catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos en proveedor!!'
        })
      } finally {
        if(load){ this.Load_proveedor = false; }
      }    
    },
    llenar_modal_proveedor(editable,proveedor){
      if(editable == false){
        this.modal_proveedor = true;
        this.GE_modal.id = 0
        this.GE_modal.empresa_nombre     = '';
        this.GE_modal.rut     = '';
        this.GE_modal.empresa_pagina     = '';
        this.GE_modal.empresa_correo     = '';
        this.GE_modal.empresa_telefono   = '';
        this.GE_modal.vendedor_nombre    = '';
        this.GE_modal.vendedor_telefono  = '';
        this.GE_modal.vendedor_correo    = '';
        this.GE_modal.banco_nombre       = '';
        this.GE_modal.banco_tipocuenta   = '';
        this.GE_modal.banco_rut          = '';
        this.GE_modal.banco_numerocuenta = '';
        this.GE_modal.eliminado          = 0;
      }else{
        this.modal_proveedor = true;
        this.GE_modal.id = proveedor.id;
        this.GE_modal.rut                = proveedor.rut;
        this.GE_modal.empresa_nombre     = proveedor.nombre_empresa;
        this.GE_modal.empresa_pagina     = proveedor.web_empresa;
        this.GE_modal.empresa_correo     = proveedor.correo_empresa;
        this.GE_modal.empresa_telefono   = proveedor.telefono_empresa;
        this.GE_modal.vendedor_nombre    = proveedor.nombre_provedor;
        this.GE_modal.vendedor_telefono  = proveedor.telefono_provedor;
        this.GE_modal.vendedor_correo    = proveedor.correo_provedor;
        this.GE_modal.banco_nombre       = proveedor.nombre_banco;
        this.GE_modal.banco_tipocuenta   = proveedor.tipo_banco;
        this.GE_modal.banco_rut          = proveedor.rut_banco;
        this.GE_modal.banco_numerocuenta = proveedor.cuenta_banco;
        this.GE_modal.eliminado          = proveedor.eliminado;
      }
    },
    async Crear_Editar_proveedor(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Empresas/GE_empresa?token=${token}`, this.GE_modal );
        if(result == '0'){
          this.$Toast.fire({
            type: 'success',
            title: 'Proveedor Creada!!'
          })
        }else if(result == '1'){
          this.$Toast.fire({
            type: 'info',
            title: 'Proveedor editada'
          })
        }
        this.$socket.emit('Update_table_factura_proveedor');
        this.modal_proveedor = false;
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en guardar datos del proveedor!!'
        })
      }
    },
    async Eliminar_proveedor(id){
      try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Empresas/Eliminar_Empresa?token=${token}`, { 'id':id } );
        this.$Toast.fire({
          type: 'error',
          title: 'Proveedor eliminado!!'
        })
        this.$socket.emit('Update_table_factura_proveedor');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en eliminar proveedor!!'
        })
      }
    },
    async Devolver_proveedor(id){
      try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Empresas/Eliminar_Empresa?token=${token}`, { 'id':id } );
        this.$Toast.fire({
          type: 'success',
          title: 'Proveedor devuelto'
        })
        this.$socket.emit('Update_table_factura_proveedor');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en devolver proveedor!!'
        })
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.Listar_proveedor(true);
    });
    this.$socket.on('Update_factura_proveedor', () => {
      this.Listar_proveedor(false);
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_factura_proveedor')
  },
  computed:{
    filtrarProveedor:function(){
      var buscarLowerCase = this.input_buscar.toLowerCase();
      return this.array_proveedor.filter((proveedor) => {
        return proveedor.nombre_empresa.toLowerCase().match(buscarLowerCase) || proveedor.nombre_provedor.toLowerCase().match(buscarLowerCase);
      });
    }
  },
}
</script>

<style src="./Tab01.scss" lang="scss" scoped />
