<template src="./Template.vue"></template>

<script>
import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: 'btn btn-success',
  cancelButtonClass: 'btn btn-danger',
  buttonsStyling: false,
  reverseButtons: true,
  allowEnterKey:false,
  showCancelButton: true,
})

export default {
  name: 'Informes',
  components: { Widget, Loading },
  data() {
    return {
      datos:{     fecha   :  moment().format('YYYY-MM-DD'),
                  DyM     : 1 },
      array_informe       : [],
      input_Efectivo      : null,
      input_Tarjeta       : null,
      input_Transferencia : null,
      input_Mes           : null,
      Load_informe        : false,
    }
  },
  methods:{
    async Llenar_informe(load){
      try {
        if(load){ this.Load_informe = true; }
        let token = await localStorage.getItem("user_token");
        let resInforme = await this.$JQuery.post(this.$Base_url + `Informe/Listar_informe?token=${token}`, this.datos );
        let result = JSON.parse(resInforme)
        
        this.array_informe         = result.resultados ;
        this.input_Efectivo        = result.totalEfectivo;
        this.input_Tarjeta         = result.totalTargeta;
        this.input_Transferencia   = result.totalTransferencia;
        this.input_Mes             = result.totalInforme;
        
			} catch (error) {
        if(load){ this.Load_informe = false; }
          this.$Toast.fire({
            type: 'error',
            title: 'Falla en cargar datos!!'
          })
      } finally {
        this.Load_informe = false;
      } 
    },
    async Eliminar(venta){
      var diagolo = await swalWithBootstrapButtons.fire({
                    title: 'Quisiera eliminar la venta!!!',
                    text: "",
                    type: 'error',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar!',
                  }).then((result) => { return (result.value) ?  true : false })
    try {
      if(diagolo){
        let arreglo = { 'id': venta.id, 'id_producto': venta.id_producto, 'cantidad': venta.cantidad
                    };
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Informe/devolver_producto?token=${token}`, arreglo );
        this.$Toast.fire({
          type: 'success',
          title: 'El producto fue devuelto.'
        })
      }
    } catch (error) {
      //error
    }finally{
    this.$socket.emit('Update_table_venta');
    this.$socket.emit('Update_table_producto');
    }
      
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.Llenar_informe(true)
      this.datos.fecha = moment().format('YYYY-MM-DD')
    })
    this.$socket.on('Update_venta', () => {
      this.Llenar_informe(false)
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_venta');
    this.datos.fecha;
  },
}
</script>

<style src="./Informes.scss" lang="scss" scoped />
