import Vue from 'vue';
import Vuex from 'vuex';

import layout from './layout';
import module_text from './module_text';
import module_paginas from './module_paginas';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    module_text,
    module_paginas
  }
});
