<template src="./Template.vue"></template>

<script>
import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import Swal from 'sweetalert2'
import { VueContext } from 'vue-context';

// const swalWithBootstrapButtons = Swal.mixin({
//   confirmButtonClass: 'btn btn-success',
//   cancelButtonClass: 'btn btn-danger',
//   buttonsStyling: false,
//   reverseButtons: true,
//   allowEnterKey:false,
//   showCancelButton: true,
// })

export default {
  name: 'Mecanica',
  components: { Widget, Loading, VueContext },
  data() {
    return {
      Load_clientes   : false,
      Load_trabajos   : false,
      modal_cliente   : false,
      input_buscar_c  : '',
      input_buscar_t  : '',
      array_clientes  : [],
      array_trabajos  : [],
      checbox_clientes: false,
      filtro          : { id_cliente        : 0,
                          eliminado_cliente : 0,
                          estado_trabajo    : 'Por Entregar',
                          eliminado_trabajo : 1
                        },
      Select_trabajo  : { id_cliente    : '0',
                          selected      : 'POR ENTREGAR',
                          eliminado     : false  },
      Input_cliente   : { id_cliente    : '0',
                          nombre        : '',
                          telefono_fijo : '',
                          telefono_celu : '',
                          eliminado     : '0'  },
      NUM_RESULTS     : 20,
      pag             : 1,
      NUM_RESULTS1    : 20,
      pag1            : 1,
    }
  },
  methods:{
    Ordenar_clientes(estado){
      if(estado !== this.filtro.id_cliente){
        this.filtro.id_cliente = estado;
        // this.Llenar_trabajos(true);
      }
    },
    Ordenar_clientes_activo(estado){
      if(estado == this.filtro.id_cliente){
        return 'active'
      }else{
        return ''
      }
    },
    Ordenar_trabajos(estado){
      if(estado !== this.filtro.estado_trabajo){
        this.filtro.estado_trabajo = estado;
        this.Llenar_trabajos(true);
      }
    },
    Ordenar_trabajos_activo(estado){
      if(estado == this.filtro.estado_trabajo){
        return 'active'
      }else{
        return ''
      }
    },
    todos_trabajos(){
      if(this.filtro.eliminado_trabajo == 1){
        this.Llenar_trabajos(true)
      }else{
        this.Llenar_trabajos_eliminados(true)
      }
    },
    async Llenar_clientes(load) {
      try {
        if(load){ this.Load_clientes = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Mecanica/Listar_clientes?token=${token}`);
        this.array_clientes = JSON.parse(result);
			} catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos de clientes!!'
        })
      } finally {
        if(load){ this.Load_clientes = false; }
      }    
    },
    async Llenar_trabajos(load) {
      try {
        if(load){ this.Load_trabajos = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Mecanica/Listar_trabajos_activos?token=${token}`, this.filtro );
        this.array_trabajos = JSON.parse(result);
			} catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos de trabajo!!'
        })
      } finally {
        if(load){ this.Load_trabajos = false; }
      }    
    },
    async Llenar_trabajos_eliminados(load) {
      try {
        if(load){ this.Load_trabajos = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Mecanica/Listar_trabajo_Eliminados?token=${token}`, this.filtro );
        this.array_trabajos = JSON.parse(result);
			} catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos de trabajo!!'
        })
      } finally {
        if(load){ this.Load_trabajos = false; }
      }    
    },
    llenar_modal_cliente(editable,cliente){
      if(editable == false){
        this.modal_cliente = true;
        this.Input_cliente.id_cliente     = 0;
        this.Input_cliente.nombre         = '';
        this.Input_cliente.telefono_fijo  = '';
        this.Input_cliente.telefono_celu  = '';
        this.Input_cliente.eliminado      = '0';
      }else{
        this.modal_cliente = true;
        this.Input_cliente.id_cliente     = cliente.id;
        this.Input_cliente.nombre         = cliente.nombre;
        this.Input_cliente.telefono_fijo  = cliente.telefono_fijo;
        this.Input_cliente.telefono_celu  = cliente.telefono_celular;
        this.Input_cliente.eliminado      = cliente.eliminado;
      }
    },
    Crear_editar_cliente(){
      // var a = this;
      // this.$JQuery.post(this.$Base_url + 'mecanica/GE_cliente', this.Input_cliente,
      //   function(respuesta){
      //     if(respuesta == '0'){
      //       a.$Toast.fire({
      //         type: 'success',
      //         title: 'cliente Creada!!'
      //       })
      //     }else if(respuesta == '1'){
      //       a.$Toast.fire({
      //         type: 'info',
      //         title: 'cliente editada'
      //       })
      //     }
      //     a.$socket.emit('Update_table_guia_dueno');
      //     a.modal_cliente = false;
      // })
      // .fail(function() {
      //   a.$Toast.fire({
      //     type: 'error',
      //     title: 'Falla en guardar datos del cliente!!'
      //   })
      // })
    },
    eliminar_cliente(){
      // var a = this;
      // swalWithBootstrapButtons.fire({
      //   title: 'Quisiera eliminar al cliente!!!',
      //   text: "",
      //   type: 'error',
      //   confirmButtonText: 'Si, eliminar',
      //   cancelButtonText: 'No, cancelar!!',
      // }).then((result) => {
      //   if (result.value) {
      //     a.$JQuery.post(a.$Base_url + 'mecanica/Eliminar_cliente', {'id': id },
      //       function(respuesta){
      //         if(respuesta == '0'){
      //           a.$Toastast.fire({
      //             type: 'error',
      //             title: 'Falla en eliminar cliente!!'
      //           })
      //         }else if(respuesta == '1'){
      //           a.$socket.emit('Update_table_guia_dueno');
      //           a.$Toast.fire({
      //             type: 'success',
      //             title: 'Cliente eliminada'
      //           })
      //         }
      //     })
      //     .fail(function() {
      //       a.$Toast.fire({
      //         type: 'error',
      //         title: 'Falla en eliminar cliente!!'
      //       })
      //     })
      //   }
      // })
    },
    devolver_cliente(){
      // var a = this;
      // swalWithBootstrapButtons.fire({
      //   title: 'Quisiera devolver al cliente!!!',
      //   text: "",
      //   type: 'error',
      //   confirmButtonText: 'Si, devolver',
      //   cancelButtonText: 'No, cancelar!!',
      // }).then((result) => {
      //   if (result.value) {
      //     a.$JQuery.post(a.$Base_url + 'mecanica/Devolver_cliente', {'id': id },
      //       function(respuesta){
      //         if(respuesta == '0'){
      //           a.$Toast.fire({
      //             type: 'error',
      //             title: 'Falla en devolver cliente!!'
      //           })
      //         }else if(respuesta == '1'){
      //           a.$socket.emit('Update_table_guia_dueno');
      //           a.$Toast.fire({
      //             type: 'success',
      //             title: 'Cliente devolver'
      //           })
      //         }
      //     })
      //     .fail(function() {
      //       a.$Toast.fire({
      //         type: 'error',
      //         title: 'Falla en devolver cliente!!'
      //       })
      //     })
      //   }
      // })
    },
    eliminar_trabajo(){
      // var a = this;
      // swalWithBootstrapButtons.fire({
      //   title: 'Quisiera eliminar el trabajo!!!',
      //   text: "",
      //   type: 'error',
      //   confirmButtonText: 'Si, eliminar',
      //   cancelButtonText: 'No, cancelar!!',
      // }).then((result) => {
      //   if (result.value) {
      //     a.$JQuery.post(a.$Base_url + 'mecanica/Eliminar_trabajo', {'id': id },
      //       function(respuesta){
      //         if(respuesta == '0'){
      //           a.$Toast.fire({
      //             type: 'error',
      //             title: 'Falla en eliminar trabajo!!'
      //           })
      //         }else if(respuesta == '1'){
      //           a.$socket.emit('Update_table_guia_trabajo');
      //           a.$Toast.fire({
      //             type: 'success',
      //             title: 'Trabajo eliminada'
      //           })
      //         }
      //     })
      //     .fail(function() {
      //       a.$Toast.fire({
      //         type: 'error',
      //         title: 'Falla en eliminar trabajo!!'
      //       })
      //     })
      //   }
      // })
    },
    async devolver_trabajo(id){
       try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post( this.$Base_url + `mecanica/Devolver_trabajo?token=${token}`, { id: id } );
        await this.Llenar_trabajos(true)
      } catch (error) {
        //error
      }

      // var a = this;
      // swalWithBootstrapButtons.fire({
      //   title: 'Quisiera devolver el trabajo!!!',
      //   text: "",
      //   type: 'error',
      //   confirmButtonText: 'Si, devolver',
      //   cancelButtonText: 'No, cancelar!!',
      // }).then((result) => {
      //   if (result.value) {
      //     a.$JQuery.post(a.$Base_url + 'mecanica/Devolver_trabajo', {'id': id },
      //       function(respuesta){
      //         if(respuesta == '0'){
      //           a.$Toast.fire({
      //             type: 'error',
      //             title: 'Falla en devolver trabajo!!'
      //           })
      //         }else if(respuesta == '1'){
      //           a.$socket.emit('Update_table_guia_trabajo');
      //           a.$Toast.fire({
      //             type: 'success',
      //             title: 'Trabajo devolver'
      //           })
      //         }
      //     })
      //     .fail(function() {
      //       a.$Toast.fire({
      //         type: 'error',
      //         title: 'Falla en devolver trabajo!!'
      //       })
      //     })
      //   }
      // })
    },
    tr_cliente_select(id){
      if(id == this.Select_trabajo.id_cliente){
        return 'tr_marcado'
      }
    },
    Buscar_cliente(Idcliente){
      if(Idcliente != this.filtro.id_cliente){
        this.filtro.id_cliente = Idcliente;
        this.Llenar_trabajos(true);
      }
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.Llenar_clientes(true);
      this.Llenar_trabajos(true);
    });
    this.$socket.on('Update_guia_dueno', () => {
      this.Llenar_clientes(true);
    });
    this.$socket.on('Update_guia_trabajo', () => {
      this.Llenar_trabajos(true);
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_guia_dueno');
    this.$socket.removeListener('Update_guia_trabajo');
  },
  computed:{
    filtrarclientes:function(){
      var buscarLowerCase = this.input_buscar_c.toLowerCase();
      return this.array_clientes.filter((clientes) => {
        return clientes.nombre.toLowerCase().match(buscarLowerCase);
      });
    },
    filtrartrabajo:function(){
      var buscarLowerCase1 = this.input_buscar_t.toLowerCase();
			return this.array_trabajos.filter((trabajos) => {
        return  trabajos.dueno.nombre.toLowerCase().match(buscarLowerCase1) ||
                trabajos.vehiculo.marca.toLowerCase().match(buscarLowerCase1) ||
                trabajos.vehiculo.modelo.toLowerCase().match(buscarLowerCase1) ||
                trabajos.vehiculo.patente.toLowerCase().match(buscarLowerCase1);
			});
    }
  }
}
</script>

<style src="./Mecanica.scss" lang="scss" scoped />
