<template>
  <div class="login-page bg-dark">
    <b-container class="">
      <h5 class="logo text-white">
        <i class="fa fa-circle text-warning" />
        Macoda
        <i class="fa fa-circle text-warning" />
      </h5>
      <Widget id="fondo_login" class="mx-auto" title="<h3 class='mt-0'>Inicie sesión</h3>" customHeader>
        <loading :active.sync="Load_login" 
          :can-cancel="false" 
          :is-full-page="false"
          color="#007196"
          :height="100"
          :width="100"></loading>
        <form class="mt" @submit.prevent="logiar">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          <div class="form-group">
            <input class="form-control no-border" v-model="login.user"
              required type="text" name="username" placeholder="Username" />
          </div>
          <div class="form-group">
            <input class="form-control no-border" v-model="login.password"
            required type="password" name="password" placeholder="Password" />
          </div>
          <!-- <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="check_session">
            <label class="form-check-label text-warning" for="exampleCheck1">Mantener sección abierta ?</label>
          </div> -->
          <div class="clearfix">
            <div class="btn-toolbar float-right">
              <b-button type="submit" size="sm" variant="inverse">Entrar</b-button>
            </div>
          </div>
        </form>
      </Widget>
    </b-container>
    <footer class="footer text-white">
      2019 &copy; Administrador de venta Macoda.
    </footer>
  </div>
</template>

<script>
window.sessionStorage.removeItem("deslogiado");
import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: 'LoginPage',
  components: { Widget, Loading },
  data() {
    return {
      errorMessage  : null,
      login         : { user: '', password:'' },
      check_session : false,
      Load_login    : false,
    };
  },
  methods: {
    async logiar(){
      try {
        this.Load_login = true;
        const result = await this.$JQuery.post(this.$Base_url + `Login/Entrar`, this.login )
        const usuario = JSON.parse(result)
        if(usuario){
          await localStorage.setItem('Logiado', true);
          await localStorage.setItem('user_id', usuario.id);
          await localStorage.setItem('user_nombre', usuario.nombre);
          await localStorage.setItem('user_usuario', usuario.usuario);
          await localStorage.setItem('user_imagen', usuario.imagen);
          await localStorage.setItem('user_token', usuario.token);
          await localStorage.setItem('sidebarStatic', false);
          this.$router.push('/app/Ventas');
        }
      } catch (error) {
        this.errorMessage = 'Usuario o contraseña mal ingresados'
      }finally{
        this.Load_login = false
      }
    }
  },
  created() {
    if (localStorage.getItem('Logiado') === 'true') {
      this.$router.push('/app/Ventas');
    }
    if (sessionStorage.getItem('deslogiado') === 'true') {
      this.errorMessage = 'Seccion expirada!!!'
      sessionStorage.removeItem("deslogiado");
    }
  },
};
</script>

<style src="./Login.scss" lang="scss" scoped />
