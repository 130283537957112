<template>
  <div>
    <b-row>
      <b-col xl="4">
        <loading :active.sync="Load_BoletaAnual" 
          :can-cancel="false" 
          :is-full-page="false"
          color="#007196"
          :height="100"
          :width="100"></loading>
        <Widget title="Boletas Anual" customHeader class="table02">
          <b-row>
            <b-col xl="5" md="4" sm="4">
              <div class="form-group">
                <input @change="Sumar_boletas(true)" v-model="fecha_año" class="form-control" type="number" min="1990" max="3000">
              </div>
            </b-col>
          </b-row>
					<div class="table-responsive">
            <table class="table table-bordered">
							<thead>
								<th>Mes</th> 
								<th >Total</th>
							</thead>
							<tbody>
                <tr v-for="boletaMes in array_sumaBoleta" :key="boletaMes.id">
                  <td v-if="boletaMes.mes == 'Total'" style="font-weight: bold">{{boletaMes.mes}}</td>
                  <td v-else>{{boletaMes.mes}}</td>
                  <td v-if="boletaMes.mes == 'Total'" style="font-weight: bold">{{boletaMes.total  | currency }}</td>
                  <td v-else>{{boletaMes.total  | currency }}</td>
                </tr>
							</tbody>
						</table>
					</div>
        </Widget>
      </b-col>
      <b-col xl="8" lg="12" md="12" sm="12">
        <b-row>
          <b-col xl="12">
            <b-row>
              <b-col xl="7">
                <Widget title="Agregar boletas" customHeader>
                  <form @submit.prevent="Agregar_boleta">
                    <b-row>
                      <b-col xl="5" md="4" sm="5">
                        <div class="form-group">
                          <input v-model="json_agregar.fecha" class="form-control" type="date" required>
                        </div>
                      </b-col>
                      <b-col xl="4" md="4" sm="4">
                        <div class="form-group">
                          <input v-model="json_agregar.input" ref="inputMonto" class="form-control" type="number" required>
                        </div>
                      </b-col>
                      <b-col xl="2" md="2" sm="2">
                        <div class="form-group">
                          <button v-if="json_agregar.editar_boleta === false" type="submit" class="btn btn-success" >Agregar</button>
                          <button v-else type="submit" class="btn btn-primary">Editar</button>
                        </div>
                      </b-col>
                    </b-row>
                  </form>
                </Widget>
              </b-col>
              <b-col xl="5">
                <Widget title="Buscar boletas" customHeader>
                  <b-row>
                    <b-col xl="8" md="6" sm="6">
                      <div class="form-group">
                        <input v-model="fecha_mes" class="form-control" type="month" @change="Listar_boletas(true)" >
                      </div>
                    </b-col>
                    <!-- <b-col xl="2" md="2" sm="2">
                      <div class="form-group">
                        <button @click="Listar_boletas(true)" class="btn btn-default">Buscar</button>
                      </div>
                    </b-col> -->
                  </b-row>
                </Widget>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="12" lg="12" md="12" sm="12">
            <loading :active.sync="Load_ListarBoletas" 
              :can-cancel="false" 
              :is-full-page="false"
              color="#007196"
              :height="100"
              :width="100"></loading>
            <Widget title="" customHeader>
              <div class="table-responsive table01">
                <table class="table table-bordered">
                  <thead>
                    <th>Mes</th> 
                    <th>Total</th>
                    <th style="width:30px"></th>
                    <th style="width:30px"></th>
                  </thead>
                  <tbody>
                    <tr v-for="boletas in array_listaBoleta" :key="boletas.id">
                      <td>{{ boletas.fecha | formatData }}</td>
                      <td>{{ boletas.monto | currency }}</td>
                      <td><button @click="json_agregar.id_boleta      = boletas.id,
                                          json_agregar.fecha          = boletas.fecha,
                                          json_agregar.input          = boletas.monto,
                                          json_agregar.editar_boleta  = true,
                                          $refs.inputMonto.focus()"
                                  type="button" class="btn btn-warning bt_tablet"><i class="fa fa-edit"></i></button></td>
                      <td><button @click="eliminar_boleta(boletas.id)" type="button" class="btn btn-danger bt_tablet"><i class="fa fa-times"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Widget>
          </b-col>
        </b-row>
        {{ fecha_mes }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')

export default {
  name: 'Boletas',
  components: { Widget, Loading },
  data() {
    return {
      fecha_año           : moment().format('YYYY'),
      fecha_mes           : moment().format('YYYY-MM'),
      json_agregar        : { id_boleta     : 0,
                              editar_boleta : false,
                              input         : '',
                              fecha         : moment().format('YYYY-MM-DD')},
      array_sumaBoleta    : [],
      array_listaBoleta   : [],
      Load_BoletaAnual    : false, 
      Load_ListarBoletas  : false 
    }
  },
  methods:{
      async Sumar_boletas(load){
        try {
          if(load){ this.Load_BoletaAnual = true; }
          let token  = await localStorage.getItem("user_token");
          let result = await this.$JQuery.post(this.$Base_url + `Boletas/Sumar_boletas?token=${token}`, {'fecha':this.fecha_año} );
          let datos = JSON.parse(result)
          this.array_sumaBoleta = [
            {id:1,  mes:'Enero',      total:datos[0]},
            {id:2,  mes:'Febrero',    total:datos[1]},
            {id:3,  mes:'Marzo',      total:datos[2]},
            {id:4,  mes:'Abril',      total:datos[3]},
            {id:5,  mes:'Mayo',       total:datos[4]},
            {id:6,  mes:'Junio',      total:datos[5]},
            {id:7,  mes:'Julio',      total:datos[6]},
            {id:8,  mes:'Agosto',     total:datos[7]},
            {id:9,  mes:'Septiembre', total:datos[8]},
            {id:10, mes:'Octubre',    total:datos[9]},
            {id:11, mes:'Noviembre',  total:datos[10]},
            {id:12, mes:'Diciembre',  total:datos[11]},
            {id:13, mes:'Total',      total:datos[12]}
          ];
        } catch (error) {
          this.$Toast.fire({
            type: 'error',
            title: 'Falla cargar datos!!'
          })
        }finally{
          if(load){ this.Load_BoletaAnual = false; }
        }
      },
      async Listar_boletas(load){
      try {
        if(load){ this.Load_ListarBoletas = true; }
        let token  = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Boletas/Listar_boletas?token=${token}`, {'fecha':this.fecha_mes} );
        this.array_listaBoleta = JSON.parse(result)
			} catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos!!'
        })
      } finally {
        this.Load_ListarBoletas = false; 
      } 
    },
    async Agregar_boleta(){
      try {
        this.fecha_mes = moment(this.json_agregar.fecha).format('YYYY-MM')
        let token  = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Boletas/Agregar_boleta?token=${token}`, this.json_agregar );
        if(result == '0'){
          this.$Toast.fire({
            type: 'success',
            title: 'Boleta agregada'
          })
        }else if(result == '1'){
          this.$Toast.fire({
            type: 'info',
            title: 'Boleta editada'
          })
        }
        this.json_agregar.id_boleta      = 0;
        this.json_agregar.editar_boleta  = false;
        this.json_agregar.input          = '';
        // this.json_agregar.fecha          = moment().format('YYYY-MM-DD');
        this.$refs.inputMonto.focus();
        await this.$socket.emit('Update_table_consumo');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Error en agregar boleta!!'
        })
      }
    },
    async eliminar_boleta(id){
      try {
        let token  = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Boletas/Eliminar_boleta?token=${token}`, { 'id': id} );
        this.$Toast.fire({
          type: 'warning',
          title: 'Boleta eliminada'
        })
        await this.$socket.emit('Update_table_consumo');
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Error en eliminar boleta!!'
        })
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.Sumar_boletas(true);
      this.Listar_boletas(true);
    })
    this.$socket.on('Update_consumo', () => {
      this.Sumar_boletas(false)
      this.Listar_boletas(false)
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_consumo')
  },
}
</script>

<style src="./Boletas.scss" lang="scss" scoped />
