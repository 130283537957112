<template src="./Template.vue"></template>

<script>

import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { VueContext } from 'vue-context';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: 'btn btn-success',
  cancelButtonClass: 'btn btn-danger',
  buttonsStyling: false,
  reverseButtons: true,
  allowEnterKey:false,
  showCancelButton: true,
})

export default {
  name: 'Productos',
  components: { Widget, Loading, VueContext },
  data() {
    return {
      modal_categoria : false,
      modal_producto  : false,
      Load_categorias : false,
      Load_productos  : false,
      Buscar_categoria: '',
      Buscar_producto : '',
      array_codigos   :[],
      array_categorias: [],
      array_productos : [],
      datos           : { id_categoria :  '0',
                          fila         : 'codigo',
                          orden        : 'ASC' ,
                          eliminados   : '0'},
      Input_categoria : { id           : 0 ,
                          nombre       : ''}, 
      Input_producto  : { id           : 0 ,
                          id_categoria : '' ,
                          codigo       : '' ,
                          detalle      : '' ,
                          codigo_barra : '' ,
                          precio       : '' ,
                          cantidad     : '' },        
      NUM_RESULTS     : 30, // Numero de resultados por página
      pag             : 1, // Página inicial 
    }
  },
  methods:{
    Ordenar_productos(fila,orden){
      if(fila !== this.datos.fila || orden !== this.datos.orden){
        this.datos.fila = fila;
        this.datos.orden = orden;
        this.Listar_productos(true);
      }
    },
    Ordenar_productos_activo(fila,orden){
      if(fila == this.datos.fila && orden == this.datos.orden){
        return 'active'
      }else{
        return ''
      }
    },
    tr_categoria_select(id){
      if(id == this.datos.id_categoria){
        return 'tr_marcado'
      }
    },
    async Listar_categorias(load){
      try {
        if(load){ this.Load_categorias = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Productos/Listar_Categorias?token=${token}`);
        this.array_categorias = JSON.parse(result)
			} catch (error) {
          this.$Toast.fire({
            type: 'error',
            title: 'Falla en cargar datos!!'
          })
      } finally {
        if(load){ this.Load_categorias = false; }
      } 
    },
    async Listar_productos(load){
      try {
        if(load){ this.Load_productos = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Productos/Listar_Productos?token=${token}`, this.datos);
        this.array_productos = JSON.parse(result)
			} catch (error) {
          this.$Toast.fire({
            type: 'error',
            title: 'Falla en cargar datos!!'
          })
      } finally {
        this.Load_productos = false; 
      } 
    },
    async Listar_productos_eliminados(load){
      try {
        if(load){ this.Load_productos = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Productos/Listar_Productos_elimninados?token=${token}`, this.datos);
        this.array_productos = JSON.parse(result)
			} catch (error) {
          this.$Toast.fire({
            type: 'error',
            title: 'Falla en cargar datos!!'
          })
      } finally {
        this.Load_productos = false; 
      } 
    },
    Buscar_todo(){
      this.datos.id_categoria = '';
      this.datos.fila = 'codigo';
      this.datos.orden = 'ASC'
      if(this.datos.eliminados == '0'){
        this.Listar_productos(true);
      }else if(this.datos.eliminados == '1'){
        this.Listar_productos_eliminados(true);
      }
    },
    Buscar_categorias(IdCategoria){
      if(IdCategoria != this.datos.id_categoria){
        this.datos.id_categoria = IdCategoria;
        if(this.datos.eliminados == '0'){
          this.Listar_productos(true);
        }else if(this.datos.eliminados == '1'){
          this.Listar_productos_eliminados(true);
        }
      }
    },
    llenar_modal_categoria(editable,categoria){
      if(editable == false){
        this.modal_categoria=true;
        this.Input_categoria.id = 0;
        this.Input_categoria.nombre = '';
      }else{
        this.modal_categoria=true;
        this.Input_categoria.id = categoria.id; 
        this.Input_categoria.nombre = categoria.nombre
      }
    },
    llenar_modal_producto(editable,producto){
      if(editable == false){
        this.modal_producto = true;
        this.Input_producto.id           = 0;
        this.Input_producto.id_categoria = '';
        this.Input_producto.codigo       = '';
        this.Input_producto.detalle      = '';
        this.Input_producto.codigo_barra = '';
        this.Input_producto.precio       = '';
        this.Input_producto.cantidad     = '';
      }else{
        this.modal_producto = true;
        this.Input_producto.id           = producto.id;
        this.Input_producto.id_categoria = producto.id_categoria;
        this.Input_producto.codigo       = producto.codigo;
        this.Input_producto.detalle      = producto.nombre;
        this.Input_producto.codigo_barra = producto.codigo_de_barra;
        this.Input_producto.precio       = producto.precio;
        this.Input_producto.cantidad     = producto.stock;
      }
    },
    async Crear_Editar_categoria(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Productos/GE_categoria?token=${token}`, this.Input_categoria);
        if(result == 0){
          this.$Toast.fire({
            type: 'success',
            title: 'Categoria Creada!!'
          })
        }else if(result == 1){
          this.$Toast.fire({
            type: 'info',
            title: 'Categoria editada'
          })
        }
        this.$socket.emit('Update_table_categoria');
        this.modal_categoria = false;
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en guardar datos de la categoria!!'
        })
      }
    },
    async Eliminar_categoria(id){
      let respuesta = await swalWithBootstrapButtons.fire({
        title: 'Quisiera eliminar esta categoria!!!',
        text: "",
        type: 'error',
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar!!',
      }).then((result) => { return (result.value) ?  true : false })

      try {
        if(respuesta){
          let token = await localStorage.getItem("user_token");
          let result = await this.$JQuery.post(this.$Base_url + `Productos/Eliminar_categoria?token=${token}` , { 'id':id } );
          if(result == 1){
            this.$Toast.fire({
              type: 'error',
              title: 'La categoria tiene producto asociados.'
            })
          }else if(result == 2){
            this.$Toast.fire({
              type: 'success',
              title: 'Categoria eliminada'
            })
            this.$socket.emit('Update_table_categoria');
          }
        }
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Error en eliminar Categoria'
        })
      }

    },
    async Crear_Editar_producto(){
      try {
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Productos/GE_Producto?token=${token}` , this.Input_producto );
        if(result == '0'){
          this.$Toast.fire({
            type: 'success',
            title: 'Producto Creada!!'
          })
        }else if(result == '1'){
          this.$Toast.fire({
            type: 'info',
            title: 'Producto editada'
          })
        }
        this.$socket.emit('Update_table_producto');
        this.modal_producto = false;
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en guardar datos del producto!!'
        })
      }
    },
    async Eliminar_producto(id){
      let respuesta = await swalWithBootstrapButtons.fire({
        title: 'Quisiera eliminar este producto!!!',
        text: "",
        type: 'error',
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar!!',
      }).then((result) => { return (result.value) ?  true : false })
      
      try {
        if(respuesta){
          let token = await localStorage.getItem("user_token");
          await this.$JQuery.post(this.$Base_url + `Productos/Eliminar_producto?token=${token}` , { 'id':id } );
          this.$socket.emit('Update_table_producto');
          this.$Toast.fire({
            type: 'success',
            title: 'Producto eliminada'
          })
        }
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en eliminar producto!!'
        })
      }
    },
    async Restaurar_producto(id){
      let respuesta = await swalWithBootstrapButtons.fire({
        title: 'Quisiera restaurar este producto!!!',
        text: "",
        type: 'error',
        confirmButtonText: 'Si, restaurar',
        cancelButtonText: 'No, cancelar!!',
      }).then((result) => { return (result.value) ?  true : false })
      
      try {
        if(respuesta){
          let token = await localStorage.getItem("user_token");
          await this.$JQuery.post(this.$Base_url + `Productos/Restaurar_producto?token=${token}` , { 'id':id } );
          this.$socket.emit('Update_table_producto');
          this.$Toast.fire({
            type: 'success',
            title: 'Producto restauraddo'
          })
        }
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en restaurar producto!!'
        })
      }
    },
    async BuscarCodigo(){
      try {
        if(this.Input_producto.codigo.trim().length >= 2 && this.Input_producto.codigo.trim().length <= 4){
          this.array_codigos = [];
          let token = await localStorage.getItem("user_token");
          let res = await this.$JQuery.post(this.$Base_url + `Productos/Buscar_codigos?token=${token}`, { 'codigo': this.Input_producto.codigo} );
          this.array_codigos = JSON.parse(res)
        }else{
          this.array_codigos = [];
        }
      } catch (error) {
        //error
      }
    },
    SeleccionCodigo(codigo){
      this.Input_producto.codigo = codigo;
      this.array_codigos = [];
    },
    Descargar_PDF(){
      let columns = [
        {title: 'Codigo', dataKey: "codigo2"}, 
        {title: 'Description', dataKey: "nombre"}, 
        {title: 'Stock', dataKey: "stock"}
      ];

      let doc = new jsPDF();
      doc.text('Productos', 15, 10);
      doc.autoTable(columns, this.array_productos);
      doc.save("export.pdf");
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.Listar_categorias(true);
      this.Listar_productos(true);
    })
    //Funciones socket.io
    this.$socket.on('Update_categoria', () => {
      this.Listar_categorias(false)
    });
    this.$socket.on('Update_producto', () => {
      if(this.datos.eliminados == '0'){
        this.Listar_productos(false)
      }else if(this.datos.eliminados == '1'){
        this.Listar_productos_eliminados(false);
      }
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_producto')
    this.$socket.removeListener('Update_categoria')
  },
  computed:{
		filtrarcategoria:function(){
			return this.array_categorias.filter((categoria) => {
				return categoria.nombre.toLowerCase().match(this.Buscar_categoria.toLowerCase());
			});
    },
    filtrarProductos:function(){
			return this.array_productos.filter((productos) => {
        return productos.codigo.toLowerCase().match(this.Buscar_producto.toLowerCase()) || 
              productos.nombre.toLowerCase().match(this.Buscar_producto.toLowerCase()) || 
              productos.codigo_de_barra.toLowerCase().match(this.Buscar_producto.toLowerCase())
			});
    },
  }
}
</script>

<style src="./Productos.scss" lang="scss" scoped />
