<template>
  <nav
    :class="{sidebar: true, sidebarStatic, sidebarOpened}"
    @mouseenter="sidebarMouseEnter"
    @mouseleave="sidebarMouseLeave"
  >
    <header class="logo">
      <router-link to="/app/ventas"><span class="text-warning ml-2"> MV &nbsp;</span> Macoda</router-link>
    </header>
    <ul class="nav">
      <NavLink
        header="Ventas"
        link="/app/ventas"
        iconName="fa fa-shopping-cart"
        index="ventas"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Infomes"
        link="/app/informes"
        iconName="fa fa-clipboard"
        index="informes"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Productos"
        link="/app/productos"
        iconName="fa fa-truck"
        index="productos"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Estadisticas"
        link="/app/estadisticas"
        iconName="fa fa-bar-chart-o"
        index="estadisticas"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Facturas"
        link="/app/facturas"
        iconName="fa fa-university"
        index="facturas"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Boletas"
        link="/app/boletas"
        iconName="fa fa-money"
        index="boletas"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Mecanica"
        link="/app/mecanica"
        iconName="fa fa-wrench"
        index="mecanica"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Catalogo"
        link="/app/catalogo"
        iconName="fa fa-file-text-o"
        index="catalogo"
        isHeader
      />
    </ul>
    <ul class="nav">
      <NavLink
        header="Configuracion"
        link="/app/configuracion"
        iconName="fa fa-cog"
        index="configuracion"
        isHeader
      />
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import isScreen from '@/core/screenHelper';
import NavLink from './NavLink/NavLink';

export default {
  name: 'Sidebar',
  components: { NavLink },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar']),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split('/');
      paths.pop();
      this.changeSidebarActive(paths.join('/'));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },
  },
  created() {
    this.setActiveByRoute();
  },
  computed: {
    ...mapState('layout', {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement,
    }),
  },
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped/>
