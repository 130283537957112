<template>
  <div>
    <input v-model="number1" @change="Feriados" type="number">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>irrenunciable</th>
          <th>nombre</th>
          <th>fecha</th>
          <th>tipo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="temp in item" :key="temp.id" >
          <td v-if="temp.irrenunciable = '1'">No</td>
          <td v-else>Si</td>
          <td>{{ temp.nombre }}</td>
          <td>{{ temp.fecha }} </td>
          <td>{{ temp.tipo }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// var token;
// if(sessionStorage.getItem('Logiado')){
//   token = window.sessionStorage.getItem('user_token')
// }else{
//   token = window.localStorage.getItem('user_token')
// }
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')

export default {
  data () {
    return {
      item:[],
      number1 : moment().format('YYYY'),
    }
  },
  methods: {
    Feriados(){
      try {
        var a = this;
        this.$JQuery.post('https://apis.digital.gob.cl/fl/feriados/' + this.number1,
          function(respuesta){
            a.item = respuesta
          })
          .fail(function() {
            a.$Toast.fire({
              type: 'error',
              title: 'Falla en cargar datos!!'
            })
        })
      } catch (error) {
        //error
      }
    }
  },
  mounted () {
    this.Feriados()
  },
}
</script>

<style src="./Calendario.scss" lang="scss" scoped />