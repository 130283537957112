<template>
  <div>
    <b-row>
      <b-col lg="12"><loading :active.sync="Load_estadisticas" 
          :can-cancel="false" 
          :is-full-page="false"
          color="#007196"
          :height="100"
          :width="100"></loading>
        <div class="form-group d-flex flex-wrap">
          <div class="form-list p-2">
            <h6>Seleccione Mes</h6>
            <input @change="TopCompras(mes,limite), button = false, pag = 1"  v-model="mes" class="form-control" type="month" style="width: 250px;">
          </div>
          <div class="form-list p-2">
            <h6>Item a Mostrar</h6>
            <input @change="TopCompras(mes,limite), button = false, pag = 1 " class="form-control" style="width: 100px;" v-model="limite" type="number">
          </div>
          <div class="form-list p-2">
            <h6>Total</h6>
            <input type="text" class="form-control mt-0 pt-0" :placeholder="total | currency" disabled>
          </div>
        </div> 
        <Barchar
          :id         = dataset.id
          :tipo       = dataset.tipo
          :encabeszado= dataset.Encabezado
          :label      = dataset.Label
          :datasets   = dataset.data
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <div class="form-group d-flex flex-wrap">
          <div class="form-list p-2">
            <h6>Seleccione Mes</h6>
            <input @change="TopCompras(mes,limite), button = false, pag = 1" v-model="mes" class="form-control" type="month" style="width: 250px;">
          </div>
          <div class="form-list p-2">
            <h6>Item a Mostrar</h6>
            <input @change="TopCompras(mes,limite), button = false, pag = 1 " class="form-control" style="width: 100px;" v-model="limite" type="number">
          </div>
          <div class="form-list p-2">
            <h6>Total</h6>
            <input type="text" class="form-control mt-0 pt-0" :placeholder="total | currency" disabled>
          </div>
        </div>
        <div class="table-responsive table01">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Codigo</th>
                <th>Detalle</th>
                <th>Cantidad</th>
                <th>Empresa</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(temp, index) in item" :key="temp.id" v-show="(pag - 1) * NUM_RESULTS <= index  && pag * NUM_RESULTS > index">
                <td>{{ index +1 }} </td>
                <td>{{ temp.codigo }} </td>
                <td>{{ temp.detalle }} </td>
                <td>{{ temp.cantidad }}</td>
                <td>{{ temp.empresa }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button v-if="pag != 1" @click.prevent="pag -= 1" type="button" class="btn buton_borrar" >Anterior</button>
        <button v-else  type="button" class="btn buton_borrar" disabled>Anterior</button>
        <button v-if="pag * NUM_RESULTS / item.length < 1" @click.prevent="pag += 1" type="button" class="btn buton_borrar mx-1" >Siguente</button>
        <button v-else type="button" class="btn buton_borrar mx-1" disabled>Siguente</button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Barchar from '@/components/Barchar/BarcharSinPrecio.vue'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')
moment.locale('es')

export default {
  components: { Barchar, Loading },
  data () {
    return {
      Top_compras:{
        id          : 'barchar',
        tipo        : 'bar',
        Encabezado  : 'Top Compras',
        Label       : ['1'],
        data        : ['1']
      },
      mes           : moment().format('YYYY-MM'),
      total         : 0,
      limite : 30,
      item:[],
      NUM_RESULTS   : 15, 
      pag           : 1,
      button        : false,
      Load_estadisticas: false,
    }
  },
  methods: {
    async TopCompras(mes,limite){
      try {
        this.Load_estadisticas = true;
        this.Top_compras.Encabezado = mes;  
        this.Top_compras.Label = [];
        this.Top_compras.data = [];
        this.item = []; 
        this.pag = 1;
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Estadisticas/Estadisticas_topCompras?token=${token}`, { 'fecha': mes, 'limite': limite} );
        var data = JSON.parse(result);
        this.item = data;
        this.total = 0
        this.Top_compras.Encabezado = this.mes;
        for (var i = 0; i < data.length; i++) {
          this.Top_compras.Label.push(data[i].codigo);
          this.Top_compras.data.push(data[i].cantidad);
          this.total += data[i].precio
        }
        this.total = this.total * 1.19;   
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos!!'
        })        
      } finally {
        this.Load_estadisticas = false;
      }
    }
  },
  mounted () {
    this.TopCompras(this.mes,this.limite);
  },
  computed:{
    dataset(){
      return this.Top_compras;
    }
  }
}
</script>

<style src="./Compras.scss" lang="scss" scoped />