<template>
<div :class="{root: true, sidebarClose, sidebarStatic}">
  <Sidebar />
  <div class="wrap">
    <Header />
    <v-touch class="content" @swipeleft="handleSwipe" @swiperight="handleSwipe" :swipe-options="{direction: 'horizontal', threshold: 100}">
      <router-view />
      <footer class="contentFooter">
        Diseñado por Daniel Perez para Macoda
      </footer>
    </v-touch>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Sidebar from '@/components/Sidebar/Sidebar';
import Header from '@/components/Header/Header';
import Swal from 'sweetalert2'

import './Layout.scss';

export default {
  name: 'Layout',
  components: { Sidebar, Header },
  methods: {
    ...mapActions(
      'layout', ['switchSidebar', 'handleSwipe', 'changeSidebarActive'],
    ),
  },
  computed: {
    ...mapState('layout', {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic,
    }),
  },
  created() {
    const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      setTimeout(() => {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }, 2500);
    }
    this.$socket.on('refrescar_pagina', (datos) => {
      window.localStorage.setItem('actualizar', datos.pagina)

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-start',
        showConfirmButton: true,
      })

      Toast.fire({
        type: 'warning',
        title: 'Se a actualizado la pagina. Por favor cierre y habra nuevamente.'
      })
    });
  }
};
</script>

<style src="./Layout.scss" lang="scss" />
