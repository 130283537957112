export default {
  namespaced: true,
  state: {
    Factura_buscar1 : '',
    Factura_buscar2 : ''
  },
  mutations: {
    setFacturaBuscar1 (state, payload){
      state.Factura_buscar1 = payload
    },
    setFacturaBuscar2 (state, payload){
      state.Factura_buscar2 = payload
    }
  },
  actions: {

  }
}