import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';
import Login from '@/pages/Login/Login';
import Pruebas from '@/pages/Pruebas/Pruebas';
// import ErrorPage from '@/pages/Error/Error';

// Main
// import AnalyticsPage from '@/pages/Dashboard/Dashboard';
import Ventas         from '@/pages/Principal/Ventas/Ventas';
import Boletas        from '@/pages/Principal/Boletas/Boletas';
import Informes       from '@/pages/Principal/Informes/Informes';
import Productos      from '@/pages/Principal/Productos/Productos';
import Estadisticas   from '@/pages/Principal/Estadisticas/Estadisticas';
  //Hijos de estadisticas
  import EstaVentaAnuales     from '@/pages/Principal/Estadisticas/Tabs/VentaAnual/Ventas';
  import EstaVentaMensual     from '@/pages/Principal/Estadisticas/Tabs/VentaMensual/Ventas';
  import EstaComprasAnual     from '@/pages/Principal/Estadisticas/Tabs/ComprasAnual/Compras';
  import EstaComprasMensual   from '@/pages/Principal/Estadisticas/Tabs/ComprasMensual/Compras';
  import EstaProductosAnual   from '@/pages/Principal/Estadisticas/Tabs/ProductosAnual/Productos';
  import EstaProductosMensual from '@/pages/Principal/Estadisticas/Tabs/ProductosMensual/Productos';
  //Hijos de estadisticas

import Facturas       from '@/pages/Principal/Facturas/Facturas';
  //Hijos de facturas
  import Tab01                from '@/pages/Principal/Facturas/Tabs/Tab01/Tab01';
  import Tab02                from '@/pages/Principal/Facturas/Tabs/Tab02/Tab02';
  import FacturasEdit         from '@/pages/Principal/Facturas/Tabs/Facturasedit/FacturaEdit'; 
  //Hijos de facturas

import Mecanica       from '@/pages/Principal/Mecanica/Mecanica';
  //Hijos de facturas
  import EditTrabajo          from '@/pages/Principal/Mecanica/EditTrabajo/EditTrabajo';
  import ImprimirMecanica     from '@/pages/Principal/Mecanica/Imprimir/Imprimir';
  //Hijos de facturas

import Catalogo       from '@/pages/Principal/Catalogo/Catalogo';

import Configuracion  from '@/pages/Principal/Configuracion/Configuracion';
  //Hijos de facturas
  import ConfUsuarios         from '@/pages/Principal/Configuracion/Tabs/Usuarios/Usuarios';
  import ConfCalendario       from '@/pages/Principal/Configuracion/Tabs/Calendario/Calendario'
  //Hijos de facturas

import Perfil         from '@/pages/Principal/Perfil/Perfil';
import PaginaError    from '@/pages/Principal/PaginaError/PaginaError';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [ 
    { path: '/pruebas', name: 'Pruebas',  component: Pruebas, },
    { path: '/login',   name: 'Login',    component: Login, },
    { path: '*', component: Login },
    { path: '/app', name: 'Layout', component: Layout, meta:{ auntentificado: true },
      children: [
        { path: 'ventas',       name: 'Ventas',       component: Ventas },
        { path: 'boletas',      name: 'Boletas',      component: Boletas },
        { path: 'informes',     name: 'Informes',     component: Informes },
        { path: 'productos',    name: 'Productos',    component: Productos },
        { path: 'estadisticas', name: 'Estadisticas', component: Estadisticas, redirect: { name: 'ventasanual' },
          children: [
            { path: 'ventasanual',      name: 'ventasanual',      component: EstaVentaAnuales },
            { path: 'ventasmensual',    name: 'ventasmensual',    component: EstaVentaMensual },
            { path: 'comprasanual',     name: 'comprasanual',     component: EstaComprasAnual },
            { path: 'comprasmensual',   name: 'comprasmensual',   component: EstaComprasMensual },
            { path: 'productosanual',   name: 'productosanual',   component: EstaProductosAnual },
            { path: 'productosmensual', name: 'productosmensual', component: EstaProductosMensual },
            { path: '*', component: PaginaError }
          ],
        },
        { path: 'facturas', name: 'Facturas', component: Facturas, redirect: { name: 'tab02' },
          children: [
            { path: 'tab01', name: 'tab01', component: Tab01 },
            { path: 'tab02', name: 'tab02', component: Tab02 }
          ],
        },
        { path: 'facturas/facturaedit/:id', name: 'Facturaedit',      component: FacturasEdit },
        { path: 'mecanica',                 name: 'Mecanica',         component: Mecanica },
        { path: 'mecanica/imprirmi/:id',    name: 'ImprimirMecanica', component: ImprimirMecanica },
        { path: 'mecanica/Trabajo/:id',     name: 'EditTrabajo',      component: EditTrabajo },
        { path: 'catalogo',                 name: 'Catalogo',         component: Catalogo },
        { path: 'configuracion',            name: 'Configuracion',    component: Configuracion,
          children: [
            { path: 'usuarios',   name: 'usuarios',   component: ConfUsuarios },
            { path: 'calendario', name: 'calendario', component: ConfCalendario },
            { path: '*', component: PaginaError }
          ],
        },
        { path: 'perfil', name: 'Perfil', component: Perfil },
        { path: 'error',  name: 'Error',  component: PaginaError },
        { path: '*', component: PaginaError },
      ],
    },
  ],
  
});

router.beforeEach((to, from, next) => {
  let usuario = false;
  if(localStorage.getItem('Logiado') === 'true'){
    usuario = true;
  }else{
    usuario = false;
  }
  let autorizacion = to.matched.some(record => record.meta.auntentificado);

  if (autorizacion && !usuario) {
    next('Login');
  }else if (!autorizacion && usuario  === 'true') {
    next('app/ventas');
  }else {
    next();
  }
})

export default router;