<template src="./Template.vue"></template>

<script>
import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Ventas',
  components: { Widget, Loading },
  data() {
    return {
      NUM_RESULTS     : 70, // cantidad de resultados por página
      pag             : 1, // Página inicial
      Array_productos : [],
      Array_temporal  : [],
      Load_venta      : false,
      Load_temp       : false,
      input_buscar    : '',
      json_producto   : { codigo     : "" },
      tipo_venta      : 1,
      input_total     : 0
    };
  },
  methods: {
    async Llenar_productos(load) {
      try {
        if(load){ this.Load_venta = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Ventas/Listar_productos?token=${token}`, this.datos );
        this.Array_productos = JSON.parse(result)
			} catch (error) {
          this.$Toast.fire({
            type: 'error',
            title: 'Falla en cargar datos en productos!!'
          })
      } finally {
          if(load){ this.Load_venta = false; }
      }    
    },
    async Llenar_temporal(load){
      try {
        if(load){ this.Load_temp = true; }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Ventas/Listar_temporal?token=${token}`, {id_usuario : localStorage.getItem('user_id')} );
        this.Array_temporal = await JSON.parse(result)
      } catch(error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos en temporal!!'
        })
      } finally {
        if(load){ this.Load_temp = false; }
      }
    },
    async Enviar_temporal(producto, valor = true){
      if(producto.stock <= 0){
        valor = await this.$Notific.fire({
                                title: 'Producto sin stock!!!',
                                text : 'Desea agregar este producto a la venta',
                                confirmButtonText: 'Si, agregar.',
                                cancelButtonText: 'No, cancelar!!',
                              }).then((result) => {
                                if (result.value) {
                                  return true
                                }
                              })  
      }
      
      if(valor){
        try {
          let token = await localStorage.getItem("user_token");
          await this.$JQuery.post(this.$Base_url + `Ventas/Enviar_temporal?token=${token}`, { 'id_usuario' : localStorage.getItem("user_id"), 'id_codigo' : producto.id });
          this.$Toast.fire({
            type: 'success',
            title: 'producto agregado'
          })
          this.Target_inputFocus();
          this.$socket.emit('Update_table_temporal', { 'usuario' : localStorage.getItem("user_id") });
        } catch (error) {
          console.log(error)
        }
      }
    },
    editar_detalle(event,temp){
      let detalle_temporal = event.target.innerHTML
      if(detalle_temporal == ''){
        this.$Toast.fire({
          type: 'error',
          title: 'El detalle no debe estar vacio'
        })
        event.target.innerHTML= temp.nombre
      }else{
        if(detalle_temporal != temp.nombre){
          this.editarTempotal(temp.id, detalle_temporal, temp.cantidad)
        }
        this.Target_inputFocus();
      }
    },
    editar_stock(event,temp){
      var cantidad_temp = event.target.innerHTML
      if(cantidad_temp == '' || cantidad_temp == 0 || cantidad_temp == ',' || cantidad_temp == '.' ){ 
        this.$Toast.fire({
          type: 'error',
          title: 'La cantidad no debe estar vacio y ser mayor a 0'
        })
        // event.target.focus();
        event.target.innerHTML= temp.cantidad
      }else{
        if(cantidad_temp != temp.cantidad){
          this.editarTempotal(temp.id, temp.nombre, cantidad_temp)
        }
        this.Target_inputFocus();
      }
    },
    async editarTempotal(id,detalle,cantidad){
      try {
        let parametros = { 'id': id, 'detalle':detalle, 'cantidad':cantidad }
        let token  = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Ventas/edittemp?token=${token}`, parametros );
        this.$Toast.fire({
          type: 'success',
          title: 'Editado'
        })
      } catch (error) {
        //error
      }finally{
        this.$socket.emit('Update_table_temporal',{ 'usuario' : localStorage.getItem("user_id") });
      }
    },
    async eliminar_producto(id_producto){
      try {
        if(this.Array_temporal.length != 0){
          let token = await localStorage.getItem("user_token");
          await this.$JQuery.post(this.$Base_url + `Ventas/eliminar_temporal?token=${token}`, { 'id' : id_producto, 'id_usuario':localStorage.getItem("user_id") } );
          this.$Toast.fire({
            type: 'error',
            title: 'Producto eliminado'
          })
          this.$socket.emit('Update_table_temporal',{ 'usuario' : localStorage.getItem("user_id") });
          this.$socket.emit('tipo_venta', {'usuario':localStorage.getItem("user_id"), 'tipo_venta': this.tipo_venta  } )
          this.Target_inputFocus();
        }
      } catch (error) {
        //error
      }      
    },
    change_input_total(){
      this.$socket.emit('tipo_venta', {'usuario':localStorage.getItem("user_id"), 'tipo_venta':this.tipo_venta } )
      this.Target_inputFocus();
    },
    async Total_venta(){
      try {
        let datos = await { id_usuario: localStorage.getItem('user_id'), tipo_venta: this.tipo_venta  }
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Ventas/total_temp?token=${token}`, datos );
        this.input_total = result
      } catch (error) {
        //error
      }
    },
    async Vender(){
      try {
        let datos  =  { tipoventa: this.tipo_venta, id_usuario: localStorage.getItem('user_id') };
        let token  = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Ventas/Vender?token=${token}`, datos );
        this.$socket.emit('Update_table_temporal', { 'usuario' :localStorage.getItem('user_id')} );
        this.$socket.emit('Update_table_producto');
        this.$socket.emit('tipo_venta',{'usuario':localStorage.getItem('user_id'), 'tipo_venta': 1 , });
        this.$Toast.fire({
          type: 'success',
          title: 'Productos Vendidos'
        })
      } catch (error) {
        //error
      }finally{
        this.pag = 1;
        this.input_buscar = '';
        this.$refs.inputBuscar.value = '';
        this.Target_inputFocus();
        this.$socket.emit('Update_table_venta');
      }
    },
    async Codigo_de_barra(){
      try {
        if(this.input_buscar != ''){
          let datos =  { codigobarra: this.input_buscar, id_usuario: localStorage.getItem('user_id') };
          let token  = await localStorage.getItem("user_token");
          await this.$JQuery.post(this.$Base_url + `Ventas/CodigoBarra?token=${token}`, datos );
          this.$socket.emit('Update_table_temporal', { 'usuario' : localStorage.getItem('user_id')});
          this.$Toast.fire({
            type: 'success',
            title: 'producto agregado'
          })
        }
      } catch (error) {
        //error
      }finally{
        this.pag = 1;
        this.input_buscar = '';
        this.$refs.inputBuscar.value = '';
        this.Target_inputFocus();
      }
    },
    Solo_numeros: function(e) {
      if (this.$JQuery.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        // Permite: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Permite: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // solo permitir lo que no este dentro de estas condiciones es un return false
        return;
        }
        // Aseguramos que son numeros
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    },
    Target_inputFocus(){
      if(screen.width > 991){
        this.$refs.inputBuscar.focus();
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.Target_inputFocus();
      this.Llenar_productos(true);
      this.Llenar_temporal(true);
      this.Total_venta();
    })
    this.$socket.on('Update_producto', () => {
      this.Llenar_productos(false);
    });
    this.$socket.on('Update_temporal', (datos) => {
      if(datos.usuario == localStorage.getItem("user_id") ){
        this.Target_inputFocus();
        this.Llenar_temporal(true);
        this.Total_venta();
      }
    });
    this.$socket.on('Cambio_tipo_venta', (datos) => {
      if(datos.usuario == localStorage.getItem("user_id") ){
        this.tipo_venta = datos.tipo_venta;
        this.Total_venta();
      }
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_producto')
    this.$socket.removeListener('Update_temporal')
    this.$socket.removeListener('Cambio_tipo_venta')
  },
  computed:{
    filtrarProductos:function(){
      var buscarLowerCase = this.input_buscar.toLowerCase();
      return this.Array_productos.filter((producto) => {
        return producto.codigo.toLowerCase().match(buscarLowerCase) || producto.nombre.toLowerCase().match(buscarLowerCase) || producto.codigo_de_barra.toLowerCase().match(buscarLowerCase);
      });
    }
  },
	created(){
		/*var a = this;
		setInterval(function(){
			a.Llenar_productos();
			a.LLenar_temporales();
			a.total_venta(a.selected_tipo);
			a.teclado='';
		},2000)*/
	}
}
</script>

<style src="./Ventas.scss" lang="scss" scoped />
