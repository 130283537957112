<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  created() {
    
    const currentPath = this.$router.history.current.path;

    if (!window.localStorage.getItem('Logiado')) {
      this.$router.push('/login');
    }

    if (currentPath === '/' || currentPath === '/app' ) {
      this.$router.push('/app/Ventas');
    }

    if (window.sessionStorage.getItem('Logiado')) {
      window.sessionStorage.clear();
      window.sessionStorage.setItem('deslogiado', true)
      this.$router.push('/login');
    }
  },
};
</script>

<style src="./styles/theme.scss" lang="scss" />
