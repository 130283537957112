<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card title="Card Title" no-body>
          <b-card-header header-tag="nav">
            <b-nav card-header tabs>
              <b-nav-item to="/app/estadisticas/ventasanual" exact exact-active-class="active">Ventas anual</b-nav-item>
              <b-nav-item to="/app/estadisticas/ventasmensual" exact exact-active-class="active">Ventas mensual</b-nav-item>
              <b-nav-item to="/app/estadisticas/comprasanual" exact exact-active-class="active">Compras Anual</b-nav-item>
              <b-nav-item to="/app/estadisticas/comprasmensual" exact exact-active-class="active">Compras mensual</b-nav-item>
              <b-nav-item to="/app/estadisticas/productosanual" exact exact-active-class="active">Productos anual</b-nav-item>
              <b-nav-item to="/app/estadisticas/productosmensual" exact exact-active-class="active">Productos mensual</b-nav-item>
            </b-nav>
          </b-card-header>
          <b-card-body>
            <router-view></router-view>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  created(){
    if(this.$route.name == 'Estadisticas'){
      this.$router.push('/app/estadisticas/ventasanual');
    }
  }
}
</script>

<style src="./Estadisticas.scss" lang="scss" scoped />
