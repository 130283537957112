export default {
  namespaced: true,
  state: {
    Factura_NUM_RESULTS1: 20,
    Factura_pag1: 1,
    Factura_NUM_RESULTS2: 9,
    Factura_pag2: 1,
  },
  mutations: {
    setFacturaPag1 (state, payload){
      state.Factura_pag1 = payload
    },
    setFacturaPag2 (state, payload){
      state.Factura_pag2 = payload
    },
    sumarFacturaPag1(state, payload){
      state.Factura_pag1 = state.Factura_pag1 += payload
    },
    sumarFacturaPag2(state, payload){
      state.Factura_pag2 = state.Factura_pag2 += payload
    },
    restarFacturaPag1(state, payload){
      state.Factura_pag1 = state.Factura_pag1 -= payload
    },
    restarFacturaPag2(state, payload){
      state.Factura_pag2 = state.Factura_pag2 -= payload
    }
  },
  actions: {

  }
}