<template>
  <div class="error-page">
    <Widget title="" customHeader>
      <div class="errorContainer mx-auto">
        <h1 class="errorCode">404</h1>
        <p class="errorInfo">
            Opps, parece que esta página no existe.
        </p>
      </div>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'PaginaError',
  components: { Widget },
  methods:{
    volver(){
      this.$router.go(-1)
    }
  }
};
</script>

<style src="./PaginaError.scss" lang="scss" scoped />
