<template>
  <div>
    <b-row>
      <b-col lg="9">
        <loading :active.sync="Load_estadisticas" 
          :can-cancel="false" 
          :is-full-page="false"
          color="#007196"
          :height="100"
          :width="100"></loading>
        <div class="form-group d-flex flex-wrap">
          <div class="form-list p-2">
            <h6>Seleccione Mes</h6>
            <input @change="Venta_Mensual(mes), button = false" v-model="mes" class="form-control" type="month" style="width: 250px;">
          </div>
          <div class="form-list p-2">
            <h6>Total</h6>
            <input type="text" class="form-control mt-0 pt-0" :placeholder="total_mes | currency" disabled>
          </div>
        </div>  
        <Barchar
          :id         = dataset.id
          :tipo       = dataset.tipo
          :encabeszado= dataset.Encabezado
          :label      = dataset.Label
          :datasets   = dataset.data
        />
      </b-col>
      <b-col lg="3">
         <div class="table-responsive table01">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Mes</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(temp, index) in item" :key="temp.id" v-show="(pag - 1) * NUM_RESULTS <= index  && pag * NUM_RESULTS > index">
              <td>{{ temp.nombre }} - {{ temp.dia }} </td>
              <td>{{ temp.total | currency }}</td>
            </tr>
          </tbody>
        </table>
         </div>
        <button v-if="pag != 1" @click.prevent="pag -= 1" type="button" class="btn buton_borrar" >Anterior</button>
        <button v-else  type="button" class="btn buton_borrar" disabled>Anterior</button>
        <button v-if="pag * NUM_RESULTS / item.length < 1" @click.prevent="pag += 1" type="button" class="btn buton_borrar mx-1" >Siguente</button>
        <button v-else type="button" class="btn buton_borrar mx-1" disabled>Siguente</button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Barchar from '@/components/Barchar/Barchar.vue'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')
moment.locale('es')

export default {
  components: { Barchar, Loading },
  data () {
    return {
      ventaMensual:{
        id          : 'barchar',
        tipo        : 'bar',
        Encabezado  : '',
        Label       : [],
        data        : []
      },
      mes           : moment().format('YYYY-MM'),
      total_mes     : 0,
      item:[],
      NUM_RESULTS   : 15, 
      pag           : 1,
      button        : false,
      Load_estadisticas: false, 
    }
  },
  methods: {
    async Venta_Mensual(mes){
      try {
        this.Load_estadisticas = true;
        this.item = [];
        this.ventaMensual.Encabezado = moment(this.mes, 'YYYY-MM').format('MMMM');  
        this.ventaMensual.Label = [];
        this.ventaMensual.data = [];
        this.pag = 1;
        this.total_mes = 0
        

        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Estadisticas/Estadisticas_month?token=${token}`, { 'fecha': mes } );
        let data = JSON.parse(result)
        for (var i = 0; i < data.length; i++) {
          var fecha = this.mes + '-' + data[i].dia;
          var solo_dia = moment(fecha, 'YYYY-MM-DD').format('dddd')
          this.ventaMensual.Label.push(solo_dia + ' - ' + data[i].dia);
          this.ventaMensual.data.push(data[i].total,);
          this.item.push({ 'dia': data[i].dia, 'total': data[i].total, 'nombre': solo_dia })
          this.total_mes += data[i].total;
        }
      } catch (error) {
        this.$Toast.fire({
            type: 'error',
            title: 'Falla en cargar datos!!'
        })        
      } finally {
        this.Load_estadisticas = false;
      }
    }
  },
  mounted () {
    this.Venta_Mensual(this.mes);
    this.$socket.on('Update_venta', () => {
      this.Venta_Mensual(this.mes)
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_venta');
  },
  computed:{
    dataset(){
      return this.ventaMensual;
    }
  }
}
</script>

<style src="./Ventas.scss" lang="scss" scoped />