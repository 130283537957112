<template>
  <div>
    <canvas :id="id"></canvas>
  </div>
  
</template>

<script>
import Chart from 'chart.js';

export default {
  mounted () {
    try {
      this.$nextTick(function () {
        this.color();
        this.chart = new Chart(document.getElementById(this.id), {
          type: this.tipo,
          data: {
            labels: this.label,
            datasets: [
              {
                label: this.encabeszado,
                data: this.datasets,
                backgroundColor: this.bgColor,
                borderColor: this.bgBorder,
                borderWidth: 1
              }
            ]
          }
        });
      }) 
    } catch (error) {
      //error
    }
       
  },
  data (){
    return {
      chart    : null,
      bgColor  : [],
      bgBorder : [],
      cantidad : 30
    }
  },
  props:{
    id          : { type: String, dafault: '' },
    tipo        : { type: String, dafault: '' },
    encabeszado : { type: String, dafault: '' },
    label       : { type: Array, dafault: [] },
    datasets    : { type: Array, dafault: [] }
  },
  methods: {
    color(){
      try {
        this.bgColor.length = 0;
        this.bgBorder.length = 0;
        for (var i = 0; i < this.datasets.length; i++) { 
          var r = Math.random() * 255;
          r = Math.round(r);
          var g = Math.random() * 255;
          g = Math.round(g);
          var b = Math.random() * 255;
          b = Math.round(b);

          this.bgColor.push('rgba('+r+','+g+','+b+', 0.8)');
          this.bgBorder.push('rgba('+r+','+g+','+b+', 1)');
        }
      } catch (error) {
        //error
      }
      
    }
  },
  watch: {
    encabeszado(datos) {
      this.chart.data.datasets[0].label = datos;
      this.chart.update();
    },
    label(data) {
      this.chart.data.labels = data;
      this.chart.update();
    },
    datasets(datos) {
      this.chart.data.datasets[0].data = datos;
      this.color();
      this.chart.update();   
    }
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.destroy()
    }
  }
}
</script>