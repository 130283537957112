<template>
  <b-row>
    <b-col lg="12">
      <b-card title="Card Title" no-body>
        <b-card-header header-tag="nav">
          <b-nav card-header tabs>
            <b-nav-item to="/app/configuracion/usuarios" exact exact-active-class="active">Usuarios</b-nav-item>
            <b-nav-item to="/app/configuracion/calendario" exact exact-active-class="active">Calendario</b-nav-item>
          </b-nav>
        </b-card-header>
        <b-card-body>
          <router-view></router-view>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'Configuracion',
  created(){
    if(this.$route.name == 'Configuracion'){
      this.$router.push('/app/configuracion/usuarios');
    }
  }
}
</script>

<style src="./Configuracion.scss" lang="scss" scoped />
