<template>
  <div>
    <b-row>
      <b-col lg="11">
        <loading :active.sync="Load_estadisticas" 
          :can-cancel="false" 
          :is-full-page="false"
          color="#007196"
          :height="100"
          :width="100"></loading>
        <div class="form-group d-flex flex-wrap">
          <div class="form-list p-2">
            <h6>Seleccione Año</h6>
            <input @change="Productos_Mensual(Mes, limite), button = false, pag = 1" v-model="Mes"  class="form-control" type="month" style="width: 250px;">
          </div>
          <div class="form-list p-2">
            <h6>Item a Mostrar</h6>
            <input @change="Productos_Mensual(Mes, limite), button = false, pag = 1 " class="form-control" style="width: 100px;" v-model="limite" type="number">
          </div>
          <div class="form-list p-2">
            <h6>&#160;</h6>
            <button v-if="button" @click="Productos_Mensual(Mes, limite), button = false, pag = 1" type="button" class="btn buton_actualizar">actualizar</button>
          </div>
        </div> 
        <Barchar
          :id         = dataset.id
          :tipo       = dataset.tipo
          :encabeszado= dataset.Encabezado
          :label      = dataset.Label
          :datasets   = dataset.data
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <div class="form-group d-flex flex-wrap">
          <div class="form-list p-2">
            <h6>Seleccione Año</h6>
            <input @change="Productos_Mensual(Mes, limite), button = false, pag = 1" v-model="Mes"  class="form-control" type="month" style="width: 250px;">
          </div>
          <div class="form-list p-2">
            <h6>Item a Mostrar</h6>
            <input @change="Productos_Mensual(Mes, limite), button = false, pag = 1" class="form-control" style="width: 100px;" v-model="limite" type="number">
          </div>
          <div class="form-list p-2">
            <h6>&#160;</h6>
            <button v-if="button" @click="Productos_Mensual(Mes, limite), button = false, pag = 1" type="button" class="btn buton_actualizar">actualizar</button>
          </div>
        </div>
        <div class="table-responsive table01">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Codigo</th>
                <th>Detalle</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(temp, index) in item" :key="temp.id" v-show="(pag - 1) * NUM_RESULTS <= index  && pag * NUM_RESULTS > index">
                <td>{{ index +1 }} </td>
                <td>{{ temp.codigo }} </td>
                <td>{{ temp.nombre }} </td>
                <td>{{ temp.cantidad }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button v-if="pag != 1" @click.prevent="pag -= 1" type="button" class="btn buton_borrar" >Anterior</button>
        <button v-else  type="button" class="btn buton_borrar" disabled>Anterior</button>
        <button v-if="pag * NUM_RESULTS / item.length < 1" @click.prevent="pag += 1" type="button" class="btn buton_borrar mx-1" >Siguente</button>
        <button v-else type="button" class="btn buton_borrar mx-1" disabled>Siguente</button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Barchar from '@/components/Barchar/BarcharSinPrecio.vue'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')

export default {
  components: { Barchar, Loading },
  data () {
    return {
      compraMensual:{
        id          : 'barchar',
        tipo        : 'bar',
        Encabezado  : '',
        Label       : [],
        data        : []
      },
      Mes : moment().format('YYYY-MM'),
      limite : 30,
      item:[],
      NUM_RESULTS   : 15, 
      pag           : 1,
      button        : false,
      Load_estadisticas: false, 
    }
  },
  methods: {
    async Productos_Mensual(mes, limite){
      try {
        this.Load_estadisticas = true;
        this.item = [];
        this.compraMensual.Label = [];
        this.compraMensual.data = [];
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Estadisticas/Estadisticas_topVenta?token=${token}`, { 'fecha': mes, 'limite': limite} );
        var data = JSON.parse(result);
        this.compraMensual.Encabezado = this.Mes;
        this.item = data;
        for (var i = 0; i < data.length; i++) {
          this.compraMensual.Label.push(data[i].codigo);
          this.compraMensual.data.push(data[i].cantidad );
        }
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en cargar datos!!'
        })        
      } finally {
        this.Load_estadisticas = false;
      }
    }
  },
  mounted () {
    this.Productos_Mensual(this.Mes, this.limite);
    this.$socket.on('Update_venta', () => {
      this.button = true
    });
  },
  beforeDestroy () {
    this.$socket.removeListener('Update_venta');
  },
  computed:{
    dataset(){
      return this.compraMensual;
    }
  }
}
</script>

<style src="./Productos.scss" lang="scss" scoped />